import React, { useState } from "react";
import HomeHeader from "../../components/Header";
import { Container } from "reactstrap";
import VOUCHERS_VIEW_STRINGS from "./assets/strings";
import AddVoucherCard from "./components/AddVoucherCard";
import VouchersList from "./components/VouchersList";
import vouchersService from "../../services/vouchers.service";
import Pagination from "../../components/Pagination";

const VouchersView = () => {
  const strings = VOUCHERS_VIEW_STRINGS;
  const [page, setPage] = useState(1);
  const { vouchers, isLoading, isError, mutate } = vouchersService.useVouchers({
    page: page,
    limit: 10,
  });

  return (
    <>
      <HomeHeader headerText={strings.TITLE} />
      <Container className="bg-gradient-white mb-5">
        <AddVoucherCard mutate={mutate} />
        <VouchersList
          vouchers={vouchers}
          isLoading={isLoading}
          isError={isError}
          mutate={mutate}
        >
          <Pagination
            count={vouchers?.totalDocs}
            page={page}
            rowsPerPage={10}
            pageCount={vouchers?.totalPages}
            handleSetPage={setPage}
          />
        </VouchersList>
      </Container>
    </>
  );
};

export default VouchersView;
