import React from 'react';
import PropTypes from 'prop-types';
import {Progress, Row} from "reactstrap";

const CustomProgress = ({ value, maxValue }) => {

    const currentValue = Math.round((value / maxValue) * 100); // Percentage
    const rightValue = 100 - currentValue - 2; // Position of the circle in the progress bar (2 is the width of the circle)

    const getClassName = (currentValue) => {
        if (value <= 5) {
            return "bg-error"
        }
        else if (currentValue < 25) {
            return "bg-warning"
        }
        else if (currentValue < 50) {
            return "bg-info"
        }
        else if (currentValue >= 50) {
            return "bg-success"
        }
        else {
            return "bg-primary-default"
        }
    }

    const className = getClassName(currentValue);

    return (
        <Row className="w-100 m-0 align-items-center position-relative ">
            <div className="w-100">
                <Progress className="m-0" value={currentValue} barClassName={`${className} h-100`}/>
            </div>
            <span
                className={`position-absolute rounded-circle ${className}`}
                style={{width: "1rem", height: "1rem", right: rightValue + "%"}}
            />
        </Row>
    );
};

CustomProgress.propTypes = {
    value: PropTypes.number,
    maxValue: PropTypes.number,
};

export default CustomProgress;
