const config = {
  toolbar: [
    'heading',
    '|',
    'bold',
    'italic',
    'link',
    'bulletedList',
    'numberedList',
    '|',
    'indent',
    'outdent',
    '|',
    'blockQuote',
    'insertTable',
    'undo',
    'redo'
  ],
  language: 'es',
  heading: {
    options: [
      {
        model: 'heading1',
        title: 'Heading 1 - 32px',
        view: {
          name: 'h1',
          classes: 'text-heading-1'
        },
        class: 'ck-heading_heading1_noti-heading-1',
        converterPriority: 'high'
      },
      {
        model: 'heading2',
        title: 'Heading 2 - 24px',
        view: {
          name: 'h2',
          classes: 'text-heading-2'
        },
        class: 'ck-heading_heading2_noti-heading-2',
        converterPriority: 'high'
      },
      {
        model: 'heading3',
        title: 'Heading 3 - 18px',
        view: {
          name: 'h3',
          classes: 'text-heading-3'
        },
        class: 'ck-heading_heading3_noti-heading-3',
        converterPriority: 'high'
      },
      {
        model: 'notiexpress-body-large',
        title: 'Body Large - 18px',
        view: {
          name: 'p',
          classes: 'text-body-large'
        },
        class: 'ck-heading_paragraph_body-large'
      },
      {
        model: 'notiexpress-body-large-bold',
        title: 'Body Large bold - 18px',
        view: {
          name: 'p',
          classes: 'text-body-large-bold'
        },
        class: 'ck-heading_paragraph_body-large-bold'
      },
      {
        model: 'notiexpress-body-regular',
        title: 'Body - 16px',
        view: {
          name: 'p',
          classes: 'text-body-regular'
        },
        class: 'ck-heading_paragraph_body-regular'
      },
      {
        model: 'notiexpress-body-small',
        title: 'Body Small - 14px',
        view: {
          name: 'p',
          classes: 'text-body-small'
        },
        class: 'ck-heading_paragraph_body-small'
      },
      {
        model: 'notiexpress-dark-link-large',
        title: 'Link Large - 18px',
        view: {
          name: 'p',
          classes: 'text-link-large'
        },
        class: 'ck-heading_paragraph_dark-link-large'
      },
      {
        model: 'notiexpress-dark-link-bold',
        title: 'Link Large bold - 18px',
        view: {
          name: 'p',
          classes: 'text-link-bold'
        },
        class: 'ck-heading_paragraph_dark-link-bold'
      },
      {
        model: 'notiexpress-dark-link-regular',
        title: 'Link - 16px',
        view: {
          name: 'p',
          classes: 'text-link-regular'
        },
        class: 'ck-heading_paragraph_dark-link-regular'
      },
    ]
  }
};

export default config;