import React from "react";
import PropTypes from "prop-types";
import {Container, Row, Col, Button} from "reactstrap";
import {useHistory} from "react-router-dom";
import {ReactComponent as ArrowLeftIcon} from "assets/img/icons/notiexpress/arrow-left-icon.svg";

const HomeHeader = ({ headerText, canGoBack }) => {
  const history = useHistory();

  const goBack = () => {
    history.goBack();
  }

  return (
    <>
      <Container className="py-5" >
        <Row className="align-items-center">
          <Col xs={11}>
            <h1 className="text-heading-1">
              {headerText}
            </h1>
          </Col>
          {
            canGoBack && (
              <Col xs={1}>
                <Button
                  color="link"
                  onClick={goBack}
                >
                  <ArrowLeftIcon width={20} height={20} className="stroke-primary-default"/>
                </Button>
              </Col>
            )
          }
        </Row>
      </Container>
    </>
  );
}

HomeHeader.propTypes = {
  headerText: PropTypes.string.isRequired,
  canGoBack: PropTypes.bool,
};

export default HomeHeader;
