import React, { useState } from 'react';
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import SETTINGS_VIEW_STRINGS from "../../assets/strings";
import { ReactComponent as EllipsisVerticalIcon } from 'assets/img/icons/notiexpress/ellipsis-vertical-icon.svg';
import ModifyServiceModal from "../ModifyServiceModal";

const ServiceOptionDropdown = ({ service }: { service: { id: string, name: string, status: boolean } }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenModifyModal, setIsOpenModifyModal] = useState(false);
  //const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const strings = SETTINGS_VIEW_STRINGS.SERVICES_VIEW.TABLE;
  return (
    <>
      <Dropdown
        className="m-0"
        isOpen={isOpen}
        toggle={() => toggle()}
        onClick={(e) => e.stopPropagation()}
        style={{ width: 'fit-content' }}
      >
        <DropdownToggle
          className="m-0 p-0 px-2 border-0 w-100 btn-link text-neutro-2"
          tag={Button}
          onClick={(e) => e.preventDefault()}
        >
          <EllipsisVerticalIcon width={20} height={20} className="stroke-primary-default" />
        </DropdownToggle>
        <DropdownMenu tag="ul" className="dropdown-menu-arrow" right>
          <DropdownItem href="#" onClick={() => setIsOpenModifyModal(true)}>
            {service.status ? strings.ACTIONS.DEACTIVATE : strings.ACTIONS.ACTIVATE}
          </DropdownItem>
          {/*<DropdownItem href="#" onClick={() => setIsOpenDeleteModal(true)}>
            {strings.ACTIONS.DELETE}
          </DropdownItem>*/}
        </DropdownMenu>
      </Dropdown>
      <ModifyServiceModal isOpen={isOpenModifyModal} service={service} onReject={() => setIsOpenModifyModal(!isOpenModifyModal)} onResolve={() => setIsOpenModifyModal(false)} />
    </>
  );
};

export default ServiceOptionDropdown;
