import React, {createContext} from "react";
import useDispatch from "./hooks/useDispatch";
import {Button, ModalBody, ModalFooter} from "reactstrap";
import CustomModal from "../../components/Modals";
import {initialState} from "./reducer";

const PrintContext = createContext(initialState);

const PrintProvider = ({children}: { children: JSX.Element }) => {
    const {
        state: { state},
        base64File,
        openPrintModal,
        closePrintModal,
        setPrintData,
        downloadPDF
    } = useDispatch();

    const PrintModal = () => (
        <CustomModal
            className="w-75"
            title={state.printTitle ?? "Imprimir"}
            isOpen={state.print}
            toggle={closePrintModal}
        >
            <ModalBody>
                <embed width="100%" height="500px" src={base64File.toString()} />
            </ModalBody>
            <ModalFooter>
                <Button
                    className="btn-outline-noti-secondary"
                    data-dismiss="modal"
                    onClick={closePrintModal}
                >
                    Cerrar
                </Button>
            </ModalFooter>
        </CustomModal>
    )
    return (
        <PrintContext.Provider value={{
            state,
            actions: {
                openPrintModal,
                closePrintModal,
                setPrintData,
                downloadPDF
            }
        }}>
            {children}
            <PrintModal />
        </PrintContext.Provider>
    );
};

export {PrintContext as default, PrintProvider as Provider};