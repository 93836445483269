import React from 'react';
import PropTypes from 'prop-types';
import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import {subscriptionStatus} from "../../../../helpers/services";
import moment from "moment/moment";
import toArs from "../../../../helpers/toArs";
import CustomProgress from "../../../../components/CustomProgress";

const UserServiceCard = ({service}) => {

  const {name, amount, expirationDate, testPeriod, validityPeriod} = service;
  const getCountDaysLeft = () => {
    const today = moment();
    const expDate = moment(expirationDate);
    return expDate.diff(today, "days") > 0 ? expDate.diff(today, "days") : 0;
  };
  return (
    <Card className="shadow-card mb-3">
      <CardHeader>
        <div>
          <Row className="justify-content-between m-0">
                        <span className="text-heading-2">
                            {name}
                        </span>
            <Col xs="12" lg="5">
              {getCountDaysLeft() === 0
                ? <Row className="align-items-center">
                  <Col>
                    <span className="text-heading-2 text-danger">
                        {
                          testPeriod
                            ? "Tu periodo de prueba finalizó."
                            : "Tu suscripción finalizó."
                        }
                    </span>
                  </Col>
                </Row>
                : <Row className="align-items-center">
                  <Col>
                    <CustomProgress value={getCountDaysLeft()} maxValue={validityPeriod}/>
                  </Col>
                  <Col>
                    <span className="text-heading-2 text-neutro-2">Quedan {getCountDaysLeft()} días</span>
                  </Col>
                </Row>
              }
            </Col>
          </Row>
        </div>
      </CardHeader>
      <CardBody>
        <Row>
          <Col>
            <p className="text-body-large-bold text-secondary-default">Estado</p>
            <p className="text-body-large">{subscriptionStatus(service)}</p>
          </Col>
          <Col>
            <p className="text-body-large-bold text-secondary-default">Vencimiento</p>
            <p className="text-body-large">{moment.utc(expirationDate).format("DD/MM/YYYY")}</p>
          </Col>
          <Col>
            <p className="text-body-large-bold text-secondary-default">Costo</p>
            <p className="text-body-large">{toArs(amount) + " ARS"}</p>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

UserServiceCard.propTypes = {
  service: PropTypes.object.bind({
    name: PropTypes.string,
    amount: PropTypes.number,
    expirationDate: PropTypes.string,
    testPeriod: PropTypes.bool,
    validityPeriod: PropTypes.number,
  })

};

export default UserServiceCard;
