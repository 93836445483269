import {ID, ROLE, OWNER, ROLES, EMAIL, PHOTO, USER_STATUS} from "./consts";
import React from "react";
import {ReactComponent as CheckIcon} from "assets/img/icons/notiexpress/check-icon.svg";
import {ReactComponent as CloseIcon} from "assets/img/icons/notiexpress/close-icon.svg";
import {ReactComponent as DivideCircleIcon} from "assets/img/icons/notiexpress/divide-circle-icon.svg";

const getUserStatus = (status) => {
  switch (status) {
    case USER_STATUS.ACTIVE:
      return <CheckIcon width={20} height={20} className="stroke-success" />
    case USER_STATUS.INACTIVE:
      return <CloseIcon width={20} height={20} className="stroke-error"/>
    case USER_STATUS.PENDING:
      return <DivideCircleIcon width={20} height={20} className="stroke-warning" />
  }
}

const getCurrentId = () =>
  localStorage.getItem(ID) || sessionStorage.getItem(ID) || "";

const getCurrentRole = () =>
  localStorage.getItem(ROLE) || sessionStorage.getItem(ROLE) || "";

const getCurrentEmail = () =>
  localStorage.getItem(EMAIL) || sessionStorage.getItem(EMAIL) || "";

const getCurrentPhoto = () => localStorage.getItem(PHOTO) || sessionStorage.getItem(PHOTO) || ''

const getOwnerId = () =>
  getCurrentRole() === ROLES.COLLABORATOR
    ? localStorage.getItem(OWNER) || sessionStorage.getItem(OWNER) || ""
    : getCurrentId();

export { getCurrentId, getCurrentRole, getOwnerId, getCurrentEmail, getCurrentPhoto, getUserStatus };
