import React from 'react';
import {Card, CardBody, CardHeader, Row} from "reactstrap";
import {ReactComponent as PlusIcon} from "assets/img/icons/notiexpress/plus-icon.svg";
import VOUCHERS_VIEW_STRINGS from "../../assets/strings";
import AddVoucherForm from "../AddVoucherForm";

const AddVoucherCard = ({ mutate }) => {
  const strings = VOUCHERS_VIEW_STRINGS.ADD_VOUCHER_CARD;
  return (
    <>
      <Card className="shadow-card">
        <CardHeader >
          <Row className="m-0 align-items-center py-3">
            <PlusIcon width={24} height={24} className="stroke-primary-default mr-2"/>
            <span className="text-heading-2">{strings.TITLE}</span>
          </Row>
        </CardHeader>
        <CardBody>
          <AddVoucherForm mutate={mutate}/>
        </CardBody>
      </Card>
    </>
  );
};

AddVoucherCard.propTypes = {

};

export default AddVoucherCard;
