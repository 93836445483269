import React from 'react';
import HomeHeader from "../../components/Header";
import {Container} from "reactstrap";
import USER_VIEW_STRINGS from "./assets/strings";
import UsersHeader from "./components/UsersHeader";
import usersService from "../../services/users.service";
import UsersTable from "./components/UsersTable";

const UsersView = () => {
  const {
    data: usersTotals,
    isError: isErrorTotals,
    isLoading: isLoadingTotals,
    mutate: mutateTotals
  } = usersService.useCountUsersAndMounts();
  
  return (
    <>
      <HomeHeader
        headerText={USER_VIEW_STRINGS.TITLE}
      />
      <Container className="bg-gradient-white">
        <UsersHeader totals={usersTotals} />
        <UsersTable />
      </Container>
    </>
  );
};

export default UsersView;
