import { SERVICES_STATUS } from "./consts";
import moment from "moment";

export const subscriptionStatus = (userService) => {
  // First check if subscription is expired.
  return moment().isBefore(userService.expirationDate)
      ? (
          // Then check if is a test period or active.
          userService.isTestPeriod
              ? SERVICES_STATUS.TEST
              // Finally check if service is going to expire soon
              : (moment(userService.expirationDate).subtract(5,"days").isBefore(moment()))
                  ? SERVICES_STATUS.EXPIRED_SOON
                  : SERVICES_STATUS.ACTIVE
      )
      : SERVICES_STATUS.EXPIRED;
};

