import React from 'react';
import PropTypes from 'prop-types';
import {Button, Card, CardBody, Col, Row} from "reactstrap";
import USER_VIEW_STRINGS from "../../assets/strings";
import moment from "moment";
import {ReactComponent as UserDeleteIcon} from "assets/img/icons/notiexpress/user-delete-icon.svg";
import {ReactComponent as UserCheckIcon} from "assets/img/icons/notiexpress/user-check-icon.svg";
import {deleteUserModal} from "../DeleteUserModal";
import usersService from "../../../../services/users.service";

const UserDetailsHeader = ({user, mutate}) => {

  const handleDisableUser = async (e) => {
    e.preventDefault();
    try {
       await deleteUserModal();
       await usersService.disableUser({userId: user?.id});
       mutate();
    } catch (e) {
      //console.log(e);
    }
  };

  const handleEnableUser =  async () => {
    try {
      await usersService.enableUser({userId: user?.id});
      mutate();
    } catch (e) {
      //console.log(e);
    }
  };




  const Field = ({label, value}) => (
    <Col xs={4}>
      <p className="text-body-large-bold text-secondary-default">{label}</p>
      <p className="text-body-large">{value}</p>
    </Col>
  );
  return (
    <>
      <Card className="shadow-card">
        <CardBody>
          <Row>
            <Col xs={12} md={10}>
              <Row>
                <Field value={user?.lastName} label={USER_VIEW_STRINGS.USER_DETAILS.USER_DETAILS_HEADER.LAST_NAME}/>
                <Field value={user?.name} label={USER_VIEW_STRINGS.USER_DETAILS.USER_DETAILS_HEADER.NAME}/>
                <Field value={user?.email} label={USER_VIEW_STRINGS.USER_DETAILS.USER_DETAILS_HEADER.EMAIL}/>
              </Row>
              <Row>
                <Field
                  value={user?.createdAt ? moment(user?.createdAt).format("DD/MM/YYYY - HH:mm") : "-"}
                  label={USER_VIEW_STRINGS.USER_DETAILS.USER_DETAILS_HEADER.CREATED_AT}
                />
                <Field
                  value={user?.deletedAt ? moment(user?.deletedAt).format("DD/MM/YYYY - HH:mm") : "-"}
                  label={USER_VIEW_STRINGS.USER_DETAILS.USER_DETAILS_HEADER.DELETED_AT}
                />
                <Field value={user?.status} label={USER_VIEW_STRINGS.USER_DETAILS.USER_DETAILS_HEADER.STATUS}/>
              </Row>
            </Col>
            <Col xs={12} md={2}>
              <Row className="align-items-center justify-content-center h-100">
                <Button
                  className="btn-noti-icon-primary px-2"
                  onClick={user?.enabled ? handleDisableUser : handleEnableUser}
                >
                  {
                    user?.enabled ? (
                      <UserDeleteIcon width={24} height={24} className="stroke-primary-default"/>
                    ) : (
                      <UserCheckIcon width={24} height={24} className="stroke-primary-disabled"/>
                    )
                  }
                </Button>
                <Col xs={12}>
                  <p
                    className="text-heading-3 text-primary-default text-center"
                  >
                    {
                      user?.enabled ? (
                        USER_VIEW_STRINGS.USER_DETAILS.USER_DETAILS_HEADER.DISABLE
                      ) : (
                        USER_VIEW_STRINGS.USER_DETAILS.USER_DETAILS_HEADER.ENABLE
                      )
                    }
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>

    </>
  );
};

UserDetailsHeader.propTypes = {
  user: PropTypes.object
};

export default UserDetailsHeader;
