import React from "react";
import { Card, CardBody, CardHeader, Col } from "reactstrap";
import TableOptions from "../../../../components/TableOptions";
import Pagination from "../../../../components/Pagination";
import { TABLE_TYPES } from "../../../../helpers/consts";
import DynamicTable from "../../../../components/Tables/DynamicTable";
import usersService from "../../../../services/users.service";
import usersColumns from "../../assets/userColumns";
import USER_VIEW_STRINGS from "../../assets/strings";
import useSearchFilters from "../../../../hooks/useSearchFilters/useSearchFilters";

const UsersTable = () => {
  const rowsPerPage = 10;
  const { params, resetSearch, setParams } = useSearchFilters({
    newParams: {
      page: 1,
      limit: rowsPerPage,
    },
  });

  const { data, isError, isLoading, mutate } = usersService.useUsers({
    params,
  });

  function handleSetPage(page) {
		setParams({ ...params, page: page });
	}

  return (
    <>
      <Card className="shadow-card my-5">
        <CardHeader>
          <Col>
            <TableOptions
              tableType={TABLE_TYPES.USERS}
              mutate={mutate}
              resetSearch={resetSearch}
            >
              <Pagination
                count={data?.result.length ?? 0}
                page={data?.page ?? 0}
                rowsPerPage={rowsPerPage}
                pageCount={data?.totalPages ?? 0}
                handleSetPage={handleSetPage}
                disabled={isLoading || isError}
              />
            </TableOptions>
          </Col>
        </CardHeader>
        <CardBody>
          <DynamicTable
            columns={usersColumns}
            data={data?.result ?? []}
            isError={isError}
            isLoading={isLoading}
            linkTo="/admin/users/"
            propertyId="id"
            maxColumnWidth={'600'}
            minColumnWidth={'30'}
            noDataMessage={USER_VIEW_STRINGS.USERS_TABLE.NO_DATA}
          />
        </CardBody>
      </Card>
    </>
  );
};

export default UsersTable;
