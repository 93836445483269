import LoginView from "../views/Login";
import UsersView, { UserDetails } from "../views/Users";
import VouchersView from "../views/Vouchers";
import CommunicationsView from "../views/Communications";
import SettingsView from "../views/Settings";
import { ReactComponent as UserIcon } from "assets/img/icons/notiexpress/user-icon.svg";
import { ReactComponent as TagIcon } from "assets/img/icons/notiexpress/tag-icon.svg";
import { ReactComponent as MessageCircleIcon } from "assets/img/icons/notiexpress/message-circle-icon.svg";
import { ReactComponent as SettingsIcon } from "assets/img/icons/notiexpress/setting-icon.svg";
import HolidaysSettingsView from "../views/Settings/HolidaysSettingsView";
import SubscriptionsSettingsView from "../views/Settings/SubscriptionsSettingsView";
import ServicesSettingsView from "../views/Settings/ServicesSettingsView";

const routes = [
  //********** AUTH **********
  {
    path: "/login",
    name: "Iniciar sesión",
    icon: "",
    component: LoginView,
    layout: "/auth",
    listable: false,
  },
  //********** ADMIN **********
  {
    path: "/users/:id",
    name: "Detalle de Usuario",
    icon: UserIcon,
    component: UserDetails,
    layout: "/admin",
    listable: false,
  },
  {
    path: "/users",
    name: "Usuarios",
    icon: UserIcon,
    component: UsersView,
    layout: "/admin",
    listable: true,
  },
  {
    path: "/vouchers",
    name: "Vouchers",
    icon: TagIcon,
    component: VouchersView,
    layout: "/admin",
    listable: true,
  },
  {
    path: "/communications",
    name: "Comunicaciones",
    icon: MessageCircleIcon,
    component: CommunicationsView,
    layout: "/admin",
    listable: true,
  },
  {
    path: "/settings/holidays",
    name: "Configuraciones",
    icon: SettingsIcon,
    component: HolidaysSettingsView,
    layout: "/admin",
    listable: false,
  },
  {
    path: "/settings/subscriptions",
    name: "Configuraciones",
    icon: SettingsIcon,
    component: SubscriptionsSettingsView,
    layout: "/admin",
    listable: false,
  },
  {
    path: "/settings/services",
    name: "Configuraciones",
    icon: SettingsIcon,
    component: ServicesSettingsView,
    layout: "/admin",
    listable: false,
  },
  {
    path: "/settings",
    name: "Configuraciones",
    icon: SettingsIcon,
    component: SettingsView,
    layout: "/admin",
    listable: true,
  },
];

export default routes;
