import React, {useState} from 'react';
import {Button} from "reactstrap";
import {ReactComponent as EyeIcon} from 'assets/img/icons/notiexpress/eye-icon.svg';
import PreviewNewsletterModal from "../PreviewNewsletterModal";

const NewsletterOptions = ({newsletter} : {newsletter : string}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  return (
    <>
      <Button
        color="link"
        onClick={() => setIsOpen(true)}
      >
        <EyeIcon width={20} height={20} className="stroke-primary-default"/>
      </Button>
      <PreviewNewsletterModal isOpen={isOpen} toggle={toggle} newsletter={newsletter} />
    </>
  );
};

export default NewsletterOptions;
