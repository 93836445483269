import useSWR from "swr";
import { API } from "../helpers/fetch";

const generalService = {
  useJurisdictions,
  enableService,
  disableService,
  toggleService,
};
const fetch = ([url, params]) => API.get(url, params ? { params } : {});
function useJurisdictions() {
  const { data, error, mutate } = useSWR(["/noticenter/scraper/jurisdictions"], fetch);
  return {
    jurisdictions: data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
}

function enableService() {
  return API.put(`/noticenter/scraper/enable`);
}
function disableService() {
  return API.put(`/noticenter/scraper/disable`);
}
async function toggleService(serviceId, status) {
  return await API.put(`/noticenter/scraper/status/${serviceId}`, { status });
}

export default generalService;
