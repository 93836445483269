import React from 'react';
import PropTypes from 'prop-types';
import {Button, Card, CardBody, Col, Row} from "reactstrap";
import {Link} from "react-router-dom";
import SETTINGS_VIEW_STRINGS from "../../assets/strings";
import {ReactComponent as SettingIcon} from "assets/img/icons/notiexpress/setting-icon.svg";

const SettingsItemList = ({setting}) => {
  const Icon = setting.icon;
  const strings = SETTINGS_VIEW_STRINGS;
  return (
    <>
      <Card className="shadow-item my-3 py-2">
        <CardBody>
          <Row className="m-0 align-items-center h-100">
            <Col xs={12} md={8} lg={9}>
              <Row className="m-0 align-items-center">
                <Icon width={24} height={24} className="stroke-primary-default mr-2"/>
                <span className="text-heading-2">{setting.title}</span>
              </Row>
            </Col>
            <Col xs={12} md={4} lg={3}>
              <Row className="m-0 align-items-center justify-content-end">
                <Button
                  className="btn-noti-primary"
                  tag={Link}
                  to={setting.link}
                >
                  <Row className="m-0 align-items-center">
                    <SettingIcon width={24} height={24} className="stroke-white mr-2"/>
                    <span className="text-button">{strings.CONFIGURE}</span>
                  </Row>
                </Button>
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

SettingsItemList.propTypes = {
  setting: PropTypes.object.isRequired
};

export default SettingsItemList;
