import React from 'react';
import COMUNICATIONS_STRINGS from "./strings";
import moment from "moment";
import NewsletterOptions from "../components/NewsletterOptions/NewsletterOptions";

const STRINGS = COMUNICATIONS_STRINGS.TABLE.COLUMNS
const newsletterColumns = [
  {
    Header: STRINGS.TITLE,
    accessor: ({ title }) => (
      <span className="text-body-regular">
        {title}
      </span>
    )
  },
  {
    Header: STRINGS.DATE,
    accessor: ({ createdAt }) => (
      <span className="text-body-regular">
        {moment(createdAt).format('DD/MM/YYYY HH:mm')}
      </span>
    ),
  },
  {
    Header: STRINGS.ACTIONS,
    accessor: '_id',
    Cell: ({ row }) => (
      <NewsletterOptions newsletter={row.original} />
    ),
  }
];

export default newsletterColumns;