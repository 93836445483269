import React from 'react';
import {useHistory} from "react-router-dom";
import { useTable } from "react-table";
import {Row, Spinner, Table} from "reactstrap";
import PropTypes from "prop-types";

const DynamicTable = ({
  columns,
  data,
  isLoading,
  isError,
  noDataMessage,
  propertyId,
  maxColumnWidth,
  minColumnWidth,
  linkTo,
}) => {
  const history = useHistory();
  const tableInstance = useTable({
    columns,
    data,
  });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
  } = tableInstance;

  const goTo = (id) => {
    if (!linkTo) return;
    history.push(linkTo + id);
  };

  return (
    <div className="position-relative">
      <>
        {/* apply the table props */}
        <Table
          className="align-items-center table table-flush"
          size="lg"
          responsive
          hover
          {...getTableProps()}
        >
          <thead className="thead-light">
          {
            // Loop over the header rows
            headerGroups.map((headerGroup, index) => (
              // Apply the header row props
              <tr key={index} {...headerGroup.getHeaderGroupProps()}>
                {
                  // Loop over the headers in each row
                  headerGroup.headers.map((column, index) => (
                    // Apply the header cell props
                    <th key={index} {...column.getHeaderProps()}>
                      {
                        // Render the header
                        column.render("Header")
                      }
                    </th>
                  ))
                }
              </tr>
            ))
          }
          </thead>

          {isLoading ? (
            <tbody>
            <tr>
              <td colSpan={visibleColumns.length}>
                <Row className="justify-content-center">
                  <Spinner color="default"/>
                </Row>
              </td>
            </tr>
            </tbody>
          ) : (
            /* Apply the table body props */
            <tbody {...getTableBodyProps()}>
            { rows.length === 0 && !isLoading && !isError
              ? (
                <tr className="" style={{cursor: "auto"}}>
                  <td colSpan={visibleColumns.length}>
                    <span className="text-body-large-bold text-secondary-default">{noDataMessage}</span>
                  </td>
                </tr>
              )
              : rows.map((row, index) => {
                // Prepare the row for display
                prepareRow(row);
                const disabled = false; // For now, all rows are enabled
                return (
                  // Apply the row props
                  <tr
                    className="text-center-tr"
                    style={linkTo ? (!disabled ? {cursor: "pointer"} : {backgroundColor: "lightgray", cursor: "auto", boxShadow: "none"}) : {}}
                    onClick={() => disabled ? {} : goTo(row.original.Expediente ? row.original.Expediente?.id : row.original[propertyId])}
                    key={index}
                    {...row.getRowProps()}
                  >
                    {
                      // Loop over the rows cells
                      row.cells.map((cell, index) => {
                        // Apply the cell props
                        return (
                          <td
                            className="text-center-tr"
                            style={{
                              maxWidth: maxColumnWidth,
                              minWidth: minColumnWidth,
                              width: '100%'
                            }}
                            key={index}
                            {...cell.getCellProps()}
                          >
                            {
                              // Render the cell contents
                              cell.render("Cell")
                            }
                          </td>
                        );
                      })
                    }
                  </tr>
                );
              })
            }
            </tbody>
          )}
        </Table>
      </>
    </div>
  );
};

DynamicTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isError: PropTypes.bool,
  noDataMessage: PropTypes.string.isRequired,
  propertyId: PropTypes.string,
  maxColumnWidth: PropTypes.string,
  minColumnWidth: PropTypes.string,
  linkTo:PropTypes.string,
};

export default DynamicTable;
