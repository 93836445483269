import React from "react";
import {Route} from "react-router-dom";

const AdminRoute = ({component: Component, ...rest}) => {

  const renderComponent = (props) => (
    <Component {...props} />
  );

  return <>
    <Route
      {...rest}
      render={renderComponent}
    />
  </>
}
export default AdminRoute;