import React from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import { AuthNavbar } from "components/Navbars"
import {Col, Container, Row} from "reactstrap";
import routes from "routes";
import {LAYOUTS} from "../../helpers/consts";

import "./Auth.css";

const AuthLayout = () => {

  const authRoutes = routes.filter((route) => route.layout === LAYOUTS.AUTH);

  const getRoutes = (authRoutes) => {
    return authRoutes
      .map((route, index) => {
        return (
          <Route
            key={index}
            path={route.layout + route.path}
            component={route.component}
            exact={route.exact}
          />
        );
      })
  }

  return (
    <>
      <div className="main-content">
      <div className="bg-auth-body"/>
      <AuthNavbar/>
      <Container className="container-admin" >
        <Col className="p-0">
          <Row className="m-0 h-100 justify-content-center" >
            <Col className="p-0"  xs="12" lg="6" md="10">
              {/* Page content */}
              <Row className="justify-content-center h-100">
                <Switch>
                  {getRoutes(authRoutes)}
                  <Redirect from="*" to="/auth/login"/>
                </Switch>
              </Row>
            </Col>
          </Row>
        </Col>
      </Container>
    </div>
    </>
  );
};

export default AuthLayout;
