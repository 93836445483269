import {getUserStatus} from "../../../helpers/user";
import React from "react";
const usersColumns = [
  {
    Header: 'Nombre y Apellido',
    accessor: (row) => `${row.name} ${row.lastName}`
  },
  {
    Header: 'Email',
    accessor: (row) => row.email ?? 'Sin email'
  },
  {
    Header: 'Alta',
    accessor: (row) => new Date(row.createdAt ?? Date.now()).toLocaleDateString()
  },
  {
    Header: 'Baja',
    accessor: (row) => row.deletedAt ? new Date(row.deletedAt).toLocaleDateString() : '-'
  },
  {
    Header: 'Estado',
    accessor: (row) => (<p className="text-center m-0">{getUserStatus(row.status)}</p> )
  },
]
 export default usersColumns;