import React from 'react';
import {Col, Row} from "reactstrap";
import PropTypes from "prop-types";

const CustomMessage = ({className, icon, message}) => {
    return (
        <Row className={className +' align-items-center'}>
            {icon}
            <Col className="p-0 ml-1">
                <span className="text-body-regular">
                    {message}
                </span>
            </Col>
        </Row>
    );
};

CustomMessage.propTypes = {
    className: PropTypes.string,
    icon: PropTypes.element,
    message: PropTypes.string
};


export default CustomMessage;
