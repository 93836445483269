import { IPrintActions, IPrintState } from "./types";
import { IPDFSettings } from "../../hooks/usePDF/types";
import moment from "moment";

const initialState: IPrintState = {
  state: {
    print: false,
    printData: null,
    printType: '',
    printTitle: '',
    printSubTitle: '',
    pdfSettings: {
      head: [[]],
      details: [],
      body: [],
      document: null,
      title: '',
      tableId: ''
    },
  },
};

const ACTIONS: Readonly<any> = Object.freeze({
  OPEN_MODAL: (state: IPrintState) => {
    return {
      ...state,
      state: {
        ...state.state,
        print: true,
      }
    }
  },
  CLOSE_MODAL: (state: IPrintState) => {
    return {
      ...state,
      state: {
        ...state.state,
        print: false,
        printData: null,
        printType: null,
        printTitle: "Print",
        printSubTitle: null,
      }
    }
  },
  SET_PRINT_DATA: (state: IPrintState, action: IPrintActions) => {
    const voucher = action.payload.printData;
    const pdfSettings: IPDFSettings = {
      head: [["Cantidad", "Descuento", "Código", "Jurisdicción", "Desde", "Hasta"]],
      body: [
        [
          voucher?.countAvailable,
          `-${voucher?.amount}%`,
          voucher?.publicId,
          voucher?.service?.idJuris[0]?.nombre,
          moment(voucher?.startDate).format("DD/MM/YYYY"),
          moment(voucher?.endDate).format("DD/MM/YYYY"),
        ],
      ],
      title: "Voucher",
    }

    return {
      ...state,
      state: {
        ...state.state,
        printData: action.payload.printData,
        printType: action.payload.printType,
        printTitle: action.payload.printTitle,
        printSubTitle: action.payload.printSubTitle,
        pdfSettings: pdfSettings
      }
    }
  },
  DOWNLOAD_PDF: (state: IPrintState) => {
    return {
      ...state,
    }
  }
});

export { initialState, ACTIONS };
const reducer = (state: IPrintState, action: IPrintActions) => ACTIONS[action.type](state, action);

export default reducer;