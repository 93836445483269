const ACTIONS = Object.freeze({
  USER_LOGGEDIN : "USER_LOGGEDIN",
  USER_LOGGEDOUT : "USER_LOGGEDOUT",
  USER_RELOADED : "USER_RELOADED",
  USER_SESSION_EXPIRED : "USER_SESSION_EXPIRED",
});

const initialState = {
  isAuthenticated: false,
  user: null,
  token: null,
};

const init = (initialState) => {
  return initialState;
}

export { initialState, ACTIONS, init};