import React, { useState } from "react";
import { ButtonGroup, ButtonToggle, Col, Collapse, Row } from "reactstrap";
import { ReactComponent as SearchIcon } from "assets/img/icons/notiexpress/search-icon.svg";
import { TABLE_TYPES } from "../../helpers/consts";
import { UsersSearch } from "./components/Search";
import { PropTypes } from "prop-types";

const TableOptions = ({ children, tableType, resetSearch }) => {
  const [activeTab, setActiveTab] = useState(0);
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    } else {
      setActiveTab(0);
    }
  };
  
  return (
    <>
      <Row>
        <Col xs={12} lg={9}>
          <ButtonGroup>
            <ButtonToggle
              active={activeTab === 1}
              onClick={() => toggleTab(1)}
              className="btn-outline-noti-secondary py-2 px-5"
            >
              <Row className="m-0 align-items-center">
                <SearchIcon
                  width={24}
                  height={24}
                  className="stroke-secondary-default"
                />
                <span className="">Buscar</span>
              </Row>
            </ButtonToggle>
          </ButtonGroup>
        </Col>
        <Col xs={12} lg={3}>
          {children}
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg={12}>
          <Collapse isOpen={activeTab === 1}>
            {tableType === TABLE_TYPES.USERS && (
              <UsersSearch resetSearch={resetSearch} />
            )}
          </Collapse>
          <Collapse isOpen={activeTab === 2}></Collapse>
          <Collapse isOpen={activeTab === 3}></Collapse>
          <Collapse isOpen={activeTab === 4}></Collapse>
        </Col>
      </Row>
    </>
  );
};

TableOptions.propTypes = {
  children: PropTypes.node,
  tableType: PropTypes.string,
};

export default TableOptions;
