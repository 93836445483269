import React from 'react';
import HomeHeader from "../../components/Header";
import SETTINGS_VIEW_STRINGS from "./assets/strings";
import {Card, CardBody, CardHeader, Container} from "reactstrap";
import subscriptionService from "../../services/subscription.service";
import DynamicTable from "../../components/Tables/DynamicTable";
import subscriptionsColumns from "./assets/subscriptionsColumns";

const SubscriptionsSettingsView = () => {
  const strings = SETTINGS_VIEW_STRINGS.SUBSCRIPTIONS_VIEW;
  const {
    services,
    isLoading,
    isError,
  } = subscriptionService.useSubscription()
  return (
    <>
      <HomeHeader headerText={strings.TITLE} canGoBack/>
      <Container>
        <Card className="shadow-card">
          <CardHeader>
            <span className="text-heading-2">{strings.SUBTITLE}</span>
          </CardHeader>
          <CardBody>
            <DynamicTable
              columns={subscriptionsColumns}
              data={services ?? []}
              isLoading={isLoading}
              isError={isError}
              noDataMessage={strings.TABLE.NO_DATA}
            />
          </CardBody>
        </Card>
      </Container>
    </>
  );
};

export default SubscriptionsSettingsView;

