import React from 'react';
import PropTypes from 'prop-types';
import CustomModal from "../../../../components/Modals";
import USER_VIEW_STRINGS from "../../assets/strings";
import {Button, Col, ModalBody, Row} from "reactstrap";
import { create } from "react-modal-promise";

const DeleteUserModal = ({isOpen, onReject, onResolve}) => {
  const STRINGS = USER_VIEW_STRINGS.USER_DETAILS.DELETE_USER
  return (
      <CustomModal
        title={STRINGS.TITLE}
        isOpen={isOpen}
        toggle={() => onReject()}
        className="w-75"
      >
        <ModalBody>
          <Row>
            <Col>
              <p className="text-heading-2">{STRINGS.SUBTITLE}</p>
              <p className="">{STRINGS.TEXT}</p>
            </Col>
          </Row>
          <Row className="m-0 py-3">
            <Button
              className="btn-outline-noti-secondary mr-2 py-1 px-5"
              onClick={onReject}
            >
              {STRINGS.CANCEL}
            </Button>
            <Button
              className="btn-noti-primary py-1 px-5"
              onClick={onResolve}
            >
              <span className="text-button">{STRINGS.CONFIRM}</span>
            </Button>
          </Row>
        </ModalBody>
      </CustomModal>
  );
};

DeleteUserModal.propTypes = {
  inOpen: PropTypes.bool,
  toggle: PropTypes.func,
  onReject: PropTypes.func,
  onResolve: PropTypes.func,
};


export const deleteUserModal = create(DeleteUserModal);
