import React from 'react';
import PropTypes from 'prop-types';
import {Card, CardBody, CardHeader} from "reactstrap";
import USER_VIEW_STRINGS from "../../assets/strings";
import DynamicTable from "../../../../components/Tables/DynamicTable";
import lastAccessedColumns from "./assets/lastAccessedColumns";

const UserLastLogin = ({lastAccessedDate}) => {
console.log(lastAccessedDate);

  return (
    <>
      <Card className="shadow-card mb-5 mt-3">
        <CardHeader>
          <span className="text-heading-2">{USER_VIEW_STRINGS.USER_DETAILS.USER_LAST_LOGIN.TITLE}</span>
        </CardHeader>
        <CardBody>
          <DynamicTable
            data={lastAccessedDate ? [lastAccessedDate] : []}
            columns={lastAccessedColumns}
            noDataMessage={USER_VIEW_STRINGS.USER_DETAILS.USER_LAST_LOGIN.NO_DATA}
          />
        </CardBody>
      </Card>
    </>
  );
};

UserLastLogin.propTypes = {
  lastAccessedDate: PropTypes.object,
};

export default UserLastLogin;
