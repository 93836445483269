import React, {useContext, useState} from 'react';
import {useFormik} from "formik";
import * as Yup from "yup";
import {authService} from "../../services/auth.service";
import {Button, Col, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Label} from "reactstrap";
import {ReactComponent as MailIcon} from "assets/img/icons/notiexpress/mail-icon.svg";
import {ReactComponent as LockIcon} from "assets/img/icons/notiexpress/lock-icon.svg";
import {ReactComponent as EyeIcon} from "assets/img/icons/notiexpress/eye-icon.svg";
import {ReactComponent as EyeOffIcon} from "assets/img/icons/notiexpress/eye-off-icon.svg";
import {ReactComponent as CloseIcon} from "assets/img/icons/notiexpress/close-icon.svg";
import CustomMessage from "../../components/CustomMessage";
import auth from "../../context/auth";
import {useHistory} from "react-router-dom";

const loginInputSchema = Yup.object().shape({
  email: Yup.string()
    .email("El email no es válido.")
    .required("Campo requerido."),
  password: Yup.string()
    .required("Campo requerido."),
  keepSession: Yup.string()
});

const LoginView = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showErrorLogin, setShowErrorLogin] = useState(false);
  const history = useHistory();
  const { actions : { loggedIn }} = useContext(auth);

  const handleSubmit = async (values, {setSubmitting}) => {
    setSubmitting(true);
    try {
      const response = await authService.login(values);
      loggedIn(response);
      history.push("/admin/users");
    } catch (e) {
      setShowErrorLogin(true)
      //console.log(e)
    } finally {
      setSubmitting(false);
    }
  }

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      keepSession: false,
    },
    validationSchema: loginInputSchema,
    onSubmit: handleSubmit,
  });

  const handleTogglePassword = () => setShowPassword(!showPassword);

  return (
    <>
      <Col className="bg-white h-100 pt-6">
        <Col className="mt-3 px-5">
          <h3 className="text-heading-2 text-lg py-3">Ingresá a tu cuenta</h3>
          <Form role="form" onSubmit={formik.handleSubmit}>
            <FormGroup className="mb-3">
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <MailIcon width={18} height={18} className="stroke-primary-default"/>
                  </InputGroupText>
                </InputGroupAddon>
                <Label for="email" hidden>
                  Email
                </Label>
                <Input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  name="email"
                  placeholder="Email"
                  type="email"
                  autoComplete="new-email"
                  required
                />
              </InputGroup>
              {formik.errors.email && formik.touched.email && (
                <span className="text-dark-link-regular text-error">{formik.errors.email}</span>
              )}
            </FormGroup>
            <FormGroup>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <LockIcon width={18} height={18} className="stroke-primary-default"/>
                  </InputGroupText>
                </InputGroupAddon>
                <Label for="password" hidden>
                  Contraseña
                </Label>
                <Input
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  onBlur={formik.handleBlur}
                  name="password"
                  placeholder="Contraseña"
                  type={showPassword ? "text" : "password"}
                  autoComplete="new-password"
                  minLength="6"
                  required
                />
                <InputGroupAddon addonType="append">
                  <InputGroupText>
                    <span onClick={handleTogglePassword}>
                        {showPassword ? <EyeOffIcon size="18"/> :
                          <EyeIcon className="stroke stroke-primary-default" size="18"/>}
                    </span>
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
              {formik.errors.password && formik.touched.password && (
                <span className="text-dark-link-regular text-error">{formik.errors.password}</span>
              )}
            </FormGroup>
            <div className="custom-control custom-control-alternative custom-checkbox mt-5">
              <Input
                onChange={formik.handleChange}
                checked={formik.values.keepSession}
                name="keepSession"
                className="custom-control-input"
                id="customCheckLogin"
                type="checkbox"
              />
              <label
                className="custom-control-label"
                htmlFor="customCheckLogin"
              >
                <span className="text-body-regular">
                    Mantener sesión iniciada
                </span>
              </label>
            </div>
            <div className="my-3">
              <Button
                className="my-4 p-1 px-5 btn btn-noti-primary"
                type="submit"
                disabled={formik.isSubmitting}
              >
                <span
                  className={`spinner-border spinner-border-sm mr-3 ${
                    formik.isSubmitting ? "" : "d-none"
                  }`}
                  role="status"
                  aria-hidden="true"
                />
                <span className="text-button ">Ingresar</span>
              </Button>
            </div>
            <div className="my-3">
              {
                showErrorLogin && (
                  <CustomMessage
                    icon={<CloseIcon width={18} height={18} className="stroke-error"/>}
                    message="Los datos ingresados son incorrectos. "
                  />
                )
              }
            </div>
          </Form>
        </Col>
      </Col>
    </>
  );
};

export default LoginView;
