import React from 'react';
import PropTypes from 'prop-types';
import { CardHeader, Modal, ModalHeader } from "reactstrap";

const CustomModal = ({title, children, isOpen, toggle, className}) => {
    return (
        <Modal className={`modal-dialog-centered ${className}`}  isOpen={isOpen} toggle={toggle} >
            <CardHeader className="bg-transparent">
                <ModalHeader className="" toggle={toggle}>
                    <span className="text-heading-2">{title}</span>
                </ModalHeader>
            </CardHeader>
            {children}
        </Modal>
    );
};

CustomModal.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
};

export default CustomModal;
