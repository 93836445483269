import React from 'react';
import {Card, CardBody, Col, Row} from "reactstrap";
import USER_VIEW_STRINGS from "../../assets/strings"
import {ReactComponent as UserIcon} from "assets/img/icons/notiexpress/user-icon.svg";
import {ReactComponent as DollarIcon} from "assets/img/icons/notiexpress/dollar-icon.svg";
import PropTypes from "prop-types";

const UsersHeader = ({totals}) => {
  return (
    <>
      <Card className="shadow-card">
        <CardBody>
          <Col>
            <Row className="align-items-center justify-content-between ">
              <Row className="m-0 align-items-center">
                <UserIcon width={24} height={24} className="stroke-primary-default"/>
                <span className="text-heading-2">{(totals?.totalUsers ?? '-') + ' ' + USER_VIEW_STRINGS.USERS}</span>
              </Row>
              <Row className="m-0 align-items-center">
                <DollarIcon width={24} height={24} className="stroke-primary-default"/>
                <span className="text-heading-2">{(totals?.totalAmount ?? '-') + ' ' + USER_VIEW_STRINGS.APROX}</span>
              </Row>
            </Row>
          </Col>
        </CardBody>
      </Card>
    </>
  );
};

UsersHeader.propTypes = {
  totals: PropTypes.object
};

export default UsersHeader;
