import React from 'react';
import HomeHeader from "../../components/Header";
import COMUNICATIONS_STRINGS from "./assets/strings";
import {Container} from "reactstrap";
import AddNewsletter from "./components/AddNewsletter";
import LastNewsletters from "./components/LastNewsletters";
import newsletterService from "../../services/newsletter.service";

const STRINGS = COMUNICATIONS_STRINGS;
const CommunicationsView = () => {
  const { newsletters, isError, isLoading } = newsletterService.useNewsletters();
  return (
    <>
      <HomeHeader headerText={STRINGS.TITLE} />
      <Container className="bg-white">
        <AddNewsletter />
        <LastNewsletters
          newsletters={newsletters}
          isError={isError}
          isLoading={isLoading}
        />
      </Container>
    </>
  );
};

export default CommunicationsView;
