import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Col,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Row,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import SETTINGS_VIEW_STRINGS from "../../assets/strings";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment/moment";
import { DateRange, DayPicker } from "react-day-picker";
import { ReactComponent as CalendarIcon } from "assets/img/icons/notiexpress/calendar-icon.svg";
import { ReactComponent as PlusIcon } from "assets/img/icons/notiexpress/plus-icon.svg";
import { ReactComponent as CloseIcon } from "assets/img/icons/notiexpress/close-icon.svg";
import { ReactComponent as CheckIcon } from "assets/img/icons/notiexpress/check-icon.svg";
import generalService from "../../../../services/general.service";
import Select from "react-select";
import makeAnimated from "react-select/animated";

import { es } from "date-fns/locale";
import defaultNonWorkingDaysService from "../../../../services/defaultNonWorkingDays.service";
import CustomMessage from "../../../../components/CustomMessage";

const strings = SETTINGS_VIEW_STRINGS.HOLIDAYS_VIEW.FORM;

const addDefaultHolidaysSchema = Yup.object().shape({
  reason: Yup.string().required("El motivo es requerido"),
  initDate: Yup.date().required("La fecha es requerida"),
  endDate: Yup.date().when(
    ["initDate", "repeat.value"],
    (initDate, repeat, schema) => {
      let days = 0;
      let limitDate = moment(initDate).add(days, "days");
      if (repeat === "MONTHLY") {
        days = 29;
        limitDate = moment(initDate).add(days, "days");
      } else if (repeat === "YEARLY") {
        days = 364;
        limitDate = moment(initDate).add(days, "days");
      } else if (repeat === "WEEKLY") {
        days = 3;
        limitDate = moment(initDate).add(days, "days");
      } else {
        return schema;
      }

      return schema.test({
        test: (value) => {
          if (!value) {
            return true;
          }
          return moment(value).isSameOrBefore(limitDate);
        },
        message: `La fecha final debe ser menor a ${moment(limitDate).format(
          "DD/MM/YYYY"
        )}`,
      });
    }
  ),
  jurisdictions: Yup.array()
    .min(1, "Debe seleccionar al menos una jurisdicción")
    .of(
      Yup.object()
        .shape({
          label: Yup.string(),
          value: Yup.string().required("La jurisdicción es requerida"),
        })
        .required("Campo requerido")
    ),
  repeat: Yup.object()
    .shape({
      label: Yup.string(),
      value: Yup.string()
        .required("La repetición es requerida")
        .oneOf(["UNIQUE", "WEEKLY", "MONTHLY", "YEARLY"]),
    })
    .required("Campo requerido"),
});

const AddDefaultHolidays = ({ mutate }: { mutate: () => void }) => {
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const { jurisdictions } = generalService.useJurisdictions();

  const [jurisdictionOptions, setJurisdictionOptions] = useState([]);

  useEffect(() => {
    if (jurisdictions) {
      setJurisdictionOptions(
        jurisdictions?.map((jurisdiction: { id: string; name: string }) => {
          return {
            value: jurisdiction.id,
            label: jurisdiction.name,
          };
        })
      );
    }
  }, [jurisdictions]);

  const AnimatedComponents = makeAnimated();
  const CustomOption = (props: any) => {
    return (
      <AnimatedComponents.Option {...props}>
        <Row className="m-0 align-items-center justify-content-between">
          <div className="m-0">
            <Row className="m-0 align-items-center">
              <span>{props.data.label}</span>
            </Row>
          </div>
          <div className="custom-control custom-control-alternative custom-checkbox">
            <Row className="m-0 align-items-center">
              <input
                type="checkbox"
                id={props.data.value}
                defaultChecked={props.isSelected}
                className="custom-control-input"
              />
              <label
                className="custom-control-label "
                htmlFor={props.data.value}
              />
            </Row>
          </div>
        </Row>
      </AnimatedComponents.Option>
    );
  };

  const initialValues = {
    reason: "",
    initDate: "",
    endDate: "",
    jurisdictions: [],
    repeat: "",
  };

  const handleSubmit = async (values: any) => {
    formik.setSubmitting(true);
    try {
      console.log(values);
      
      await defaultNonWorkingDaysService.addDefaultNonWorkingDays({
        initDate: values.initDate,
        ...(values.endDate && { endDate: values.endDate }),
        description: values.reason,
        jurisdictions: values.jurisdictions,
        repeat: values.repeat.value,
      });
      setShowSuccess(true);
      formik.resetForm();
    } catch (error) {
      //console.log(error)
      setShowError(true);
    } finally {
      formik.setSubmitting(false);
      await mutate();
      formik.resetForm();
    }
  };

  const handleResetForm = () => {
    formik.resetForm();
    setShowError(false);
    setShowSuccess(false);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: addDefaultHolidaysSchema,
    onSubmit: handleSubmit,
  });

  const repetirOptions = [
    { value: "UNIQUE", label: "Único" },
    { value: "WEEKLY", label: "Semanalmente" },
    { value: "MONTHLY", label: "Mensualmente" },
    { value: "YEARLY", label: "Anualmente" },
  ];

  const styles = {
    boxShadow: "0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02)",
    transition: "box-shadow 0.15s ease",
    borderRadius: "8px",
  };

  const disabledDays = [
    (day) => day.getDay() === 0 || day.getDay() === 6,
    (day) => day.getMonth() === 0,
  ];

  return (
    <>
      <Form onSubmit={formik.handleSubmit}>
        <Row>
          <Col>
            <FormGroup>
              <Label className="text-body-large-bold text-secondary-default">
                {strings.DATE}
              </Label>
              <InputGroup className="input-group-alternative" size="sm">
                <Input
                  className="form-control-alternative"
                  type="text"
                  name="sd"
                  id="sd"
                  placeholder={strings.DATE_PLACEHOLDER}
                  onChange={(e) => {
                    e.preventDefault();
                  }}
                  value={`${
                    formik.values?.initDate
                      ? moment(formik.values?.initDate).format("DD/MM/YYYY")
                      : ""
                  } ${
                    formik.values?.endDate
                      ? `- ${moment(formik.values?.endDate).format(
                          "DD/MM/YYYY"
                        )}`
                      : ""
                  }`}
                />
                <InputGroupAddon addonType="append">
                  <UncontrolledDropdown active>
                    <DropdownToggle
                      nav
                      className="h-100 d-flex align-items-center"
                    >
                      <CalendarIcon
                        width={20}
                        height={20}
                        className="stroke-primary-default"
                      />
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DayPicker
                        style={{ width: "100%" }}
                        mode="range"
                        locale={es}
                        selected={{
                          from: formik.values?.initDate,
                          to: formik.values?.endDate,
                        }}
                        onSelect={(dateRange) => {
                          formik.setFieldValue("initDate", dateRange?.from);
                          formik.setFieldValue("endDate", dateRange?.to);
                        }}
                        disabled={disabledDays}
                        onDayBlur={() => {
                          formik.setFieldTouched("initDate", true);
                          formik.setFieldTouched("endDate", true);
                        }}
                      />
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </InputGroupAddon>
              </InputGroup>
              {formik.errors?.initDate && formik.touched.initDate && (
                <Row className="ml-1 mt-2">
                  <span className="text-link-regular text-error">
                    {formik.errors?.initDate}
                  </span>
                </Row>
              )}
              {formik.errors?.endDate && formik.touched.endDate && (
                <Row className="ml-1 mt-2">
                  <span className="text-link-regular text-error">
                    {formik.errors?.endDate}
                  </span>
                </Row>
              )}
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label className="text-body-large-bold text-secondary-default">
                {strings.JURISDICTION}
              </Label>
              <Select
                className="w-100"
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                options={jurisdictionOptions ?? []}
                onChange={(newValue) => {
                  formik.setFieldValue("jurisdictions", newValue);
                  formik.setFieldTouched("jurisdictions", true);
                }}
                value={formik.values.jurisdictions}
                onBlur={() => {
                  formik.setFieldTouched('jurisdictions', true);
                }}
                components={{ ...AnimatedComponents, Option: CustomOption }}
                isMulti
                isDisabled={formik.isSubmitting}
                name="selectedEmails"
                placeholder="Seleccione una opción"
                styles={{
                  ...styles,
                  control: (base) => ({
                    ...base,
                    ...styles,
                    border:
                      formik.errors.jurisdictions && formik.touched.jurisdictions
                        ? "2px solid #f5365c"
                        : "2px solid #D1E7F3",
                  }),
                }}
              />
              {formik.touched.jurisdictions && formik.errors.jurisdictions && (
                <span className="text-error text-body-small">
                  {formik.errors.jurisdictions}
                </span>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label className="text-body-large-bold text-secondary-default">
                {strings.MOTIVE}
              </Label>
              <InputGroup className="input-group-alternative" size="sm">
                <Input
                  className="form-control-alternative"
                  type="text"
                  name="reason"
                  placeholder={strings.MOTIVE_PLACEHOLDER}
                  id="reason"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.reason}
                />
              </InputGroup>
              {formik.errors.reason && formik.touched.reason && (
                <Row className="ml-1 mt-2">
                  <span className="text-link-regular text-error">
                    {formik.errors.reason}
                  </span>
                </Row>
              )}
            </FormGroup>
          </Col>
          <Col>
            <FormGroup className="m-0 w-100">
              <Label className="text-body-large-bold text-secondary-default">
                Repetir *
              </Label>
              <Select
                options={repetirOptions ?? []}
                onChange={(newValue) => {
                  formik.setFieldValue("repeat", newValue);
                }}
                onBlur={() => {
                  formik.setFieldTouched("repeat", true);
                }}
                value={formik.values.repeat}
                isDisabled={formik.isSubmitting}
                name="selectedRepeat"
                placeholder="Seleccione una opción"
                styles={{
                  ...styles,
                  control: (base) => ({
                    ...base,
                    ...styles,
                    border:
                      formik.errors.repeat && formik.touched.repeat
                        ? "2px solid #f5365c"
                        : "2px solid #D1E7F3",
                  }),
                }}
              />
              {formik.errors.repeat && formik.touched.repeat && (
                <Row className="ml-1 mt-2">
                  <span className="text-link-regular text-error">
                    {formik.errors.repeat.value}
                  </span>
                </Row>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Row className="m-0 align-items-center">
              <Button
                className="btn-noti-primary py-1 px-5"
                type="submit"
                disabled={formik.isSubmitting || !formik.isValid}
              >
                <Row className="m-0 align-items-center">
                  {formik.isSubmitting ? (
                    <Spinner size="sm" color="light" className="mr-2" />
                  ) : (
                    <PlusIcon
                      width={24}
                      height={24}
                      className="stroke-white mr-2"
                    />
                  )}
                  <span className="text-button">{strings.SUBMIT}</span>
                </Row>
              </Button>
              <Button
                color="link"
                onClick={handleResetForm}
                disabled={formik.isSubmitting}
              >
                <Row className="m-0 align-items-center">
                  <CloseIcon
                    width={20}
                    height={20}
                    className="stroke-primary-default mr-2"
                  />
                  <span className="text-body-regular text-primary-default">
                    {strings.CLEAR}
                  </span>
                </Row>
              </Button>
              <Col className="align-items-center d-flex">
                {showError && setTimeout(() => setShowError(false), 2000) && (
                  <CustomMessage
                    icon={
                      <CloseIcon
                        width={24}
                        height={24}
                        className="stroke-error"
                      />
                    }
                    message={strings.ERROR}
                  />
                )}
                {showSuccess &&
                  setTimeout(() => setShowSuccess(false), 2000) && (
                    <CustomMessage
                      icon={
                        <CheckIcon
                          width={24}
                          height={24}
                          className="stroke-success"
                        />
                      }
                      message={strings.SUCCESS}
                    />
                  )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </>
  );
};

AddDefaultHolidays.propTypes = {
  mutate: PropTypes.func,
};

export default AddDefaultHolidays;
