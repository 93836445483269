import React from "react";
import { initialState, init } from "./assets/const";
import reducer from "./reducer";
import {createContext, useReducer} from "react";
import {useDispatch} from "./hooks/useDispatch";
import useVerifyUser from "./hooks/useVerifyUser";
import PropTypes from "prop-types";

const AuthContext  = createContext(initialState);

const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState, init);

  const {
    loggedIn,
    loggedOut,
    reloadedUser,
    sessionExpired
  } = useDispatch({dispatch});

  useVerifyUser({reloadedUser, sessionExpired});

  return (
    <AuthContext.Provider
      value={{
        state,
        actions: {
          loggedIn,
          loggedOut,
          reloadedUser
        }
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};
export { AuthProvider as Provider, AuthContext as default };