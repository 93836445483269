import { Reducer, useReducer } from "react";
import reducer, { initialState } from "../reducer";
import { IPrintActions, IPrintState } from "../types";
import usePDF from "../../../hooks/usePDF";
import moment from "moment";

export default function useDispatch() {
  const [state, dispatch] = useReducer<Reducer<IPrintState, IPrintActions>>(reducer, initialState, undefined);
  const {
    base64File,
    document,
    downloadPDF
  } = usePDF({ pdfSettings: state.state.pdfSettings });

  function openPrintModal() {
    dispatch({
      type: 'OPEN_MODAL',
    });
  }

  function closePrintModal() {
    dispatch({
      type: 'CLOSE_MODAL'
    });
  }

  function setPrintData(payload: Partial<IPrintActions>) {
    const newState = reducer(state, { type: 'SET_PRINT_DATA', payload} )
    const {downloadPDF} = usePDF({ pdfSettings: newState.state.pdfSettings });
    downloadPDF(`Voucher - Desde ${moment(newState.state.voucher?.startDate).format(
      "DD/MM/YYYY"
    )} Hasta ${moment(newState.state.voucher?.endDate).format("DD/MM/YYYY")}`)
  }

  return {
    state,
    base64File,
    document,
    openPrintModal,
    closePrintModal,
    setPrintData,
    downloadPDF
  }
}