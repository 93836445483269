import React from 'react';
import PropTypes from 'prop-types';
import DynamicTable from "../../../../components/Tables/DynamicTable";
import SETTINGS_VIEW_STRINGS from "../../assets/strings";
import holidaysColumns from "../../assets/holidaysColumns";

const DefaultHolidaysList = ({ holidays, isError, isLoading }: {holidays: any, isError: boolean, isLoading: boolean}) => {
  const strings = SETTINGS_VIEW_STRINGS.HOLIDAYS_VIEW.TABLE
  return (
    <>
      <DynamicTable
        isLoading={isLoading}
        noDataMessage={strings.NO_DATA}
        isError={isError}
        data={holidays ?? []}
        columns={holidaysColumns}
      />
    </>
  );
};

DefaultHolidaysList.propTypes = {
  holidays: PropTypes.array,
  isError: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default DefaultHolidaysList;
