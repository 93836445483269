import {useEffect} from "react";
import {authService} from "../../../services/auth.service";

const useVerifyUser = ({ reloadedUser, sessionExpired }) => {
  useEffect(() => {
    const verifyUser = async () => {
      try {
        const user = await authService.verifyUser();
        reloadedUser({ user });
      } catch (e) {
        sessionExpired();
      }
    };
    verifyUser();
  }, []);
}

export default useVerifyUser;