import React from "react";
import { Link } from "react-router-dom";
import {
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";
import {ReactComponent as BrandIcon} from "assets/img/icons/logo/notiexpress-logo-blue.svg"

const AdminNavbar = () => {
  return (
    <>
      <Navbar
        className="navbar-top border-bottom navbar-light"
        color="white"
        expand="md"
      >
        <Container className="px-4">
          <NavbarBrand  to="/" tag={Link}>
            <BrandIcon />
          </NavbarBrand>
          <button className="navbar-toggler navbar-brand " id="navbar-collapse-main">
            <span className="navbar-toggler-icon" />
          </button>
          <UncontrolledCollapse navbar toggler="#navbar-collapse-main">
            <div className="navbar-collapse-header d-md-none">
              <Row>
                <Col className="collapse-brand" xs="6">
                  <NavbarBrand to="/" tag={Link}>
                    <BrandIcon />
                  </NavbarBrand>
                </Col>
                <Col className="collapse-close" xs="6">
                  <button
                    className="navbar-toggler "
                    id="navbar-collapse-main"
                  >
                    <span />
                    <span />
                  </button>
                </Col>
              </Row>
            </div>
            <Nav className="ml-auto" navbar>
            </Nav>
          </UncontrolledCollapse>
        </Container>
      </Navbar>
    </>
  );
}

export default AdminNavbar;
