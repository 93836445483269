import { AUTH } from "./consts";

export const authHeader = () => {
  // return authorization header with basic auth credentials
  const token = localStorage.getItem(AUTH) || sessionStorage.getItem(AUTH);
  if (token) {
    return {
      Authorization: "Bearer " + token,
    };
  }
  return {};
};
