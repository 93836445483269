import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import { ReactComponent as CalendarIcon } from "assets/img/icons/notiexpress/calendar-icon.svg";
import { ReactComponent as PlusIcon } from "assets/img/icons/notiexpress/plus-icon.svg";
import { ReactComponent as CloseIcon } from "assets/img/icons/notiexpress/close-icon.svg";
import { ReactComponent as CheckIcon } from "assets/img/icons/notiexpress/check-icon.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import VOUCHERS_VIEW_STRINGS from "../../assets/strings";
import vouchersService from "../../../../services/vouchers.service";
import { DayPicker } from "react-day-picker";
import moment from "moment";
import "react-day-picker/dist/style.css";
import CustomMessage from "../../../../components/CustomMessage";

const strings = VOUCHERS_VIEW_STRINGS.ADD_VOUCHER_CARD.ADD_VOUCHER_FORM;

const addVoucherFormSchema = Yup.object().shape({
  quantity: Yup.number()
    .min(1, strings.MIN_QUANTITY_ERROR)
    .max(100, strings.MAX_QUANTITY_ERROR)
    .required(strings.REQUIRED),
  discount: Yup.number()
    .min(1, strings.DISCOUNT_ERROR)
    .max(100, strings.DISCOUNT_ERROR)
    .required(strings.REQUIRED),
  discountOption: Yup.string().required(strings.REQUIRED),
  dateRange: Yup.object().shape({
    from: Yup.date().nullable().required(strings.REQUIRED),
    to: Yup.date().nullable().required(strings.REQUIRED),
  }),
  serviceId: Yup.string().required(strings.REQUIRED),
});

const AddVoucherForm = ({ mutate }) => {
  const { services } = vouchersService.useServices();
  const [servicesOptions, setServicesOptions] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);

  const initialState = {
    quantity: 1,
    discount: 1,
    discountOption: strings.DISCOUNT_OPTIONS.PERCENTAGE,
    dateRange: {
      from: new Date(),
      to: null,
    },
    serviceId: "",
  };

  useEffect(() => {
    if (services?.services) {
      setServicesOptions(
        services?.services.map((service) => {
          return {
            value: service._id,
            label: service.nombre,
          };
        })
      );
    }
    return () => {
      setServicesOptions([]);
    };
  }, [services]);

  const handleSubmit = async (values) => {
    formik.setSubmitting(true);
    try {
      const requestBody = {
        amount: values.discount,
        countAvailable: values.quantity,
        startDate: moment(values.dateRange.from).format("YYYY-MM-DD"),
        ...(values.dateRange.to && {
          endDate: moment(values.dateRange.to).format("YYYY-MM-DD"),
        }),
        service: values.serviceId,
      };
      await vouchersService.addVoucher(requestBody);
      await mutate();
      setShowSuccess(true);
    } catch (e) {
      //console.log(e)
      setShowError(true);
    } finally {
      formik.setSubmitting(false);
    }
    //console.log(values)
  };
  const formik = useFormik({
    initialValues: initialState,
    validationSchema: addVoucherFormSchema,
    onSubmit: handleSubmit,
  });
  const handleSelectDateRange = (e) => {
    e
      ? formik.setFieldValue("dateRange", e)
      : formik.setFieldValue("dateRange", {
          from: null,
          to: null,
        });
    formik.setFieldTouched("dateRange", true);
  };

  const handleResetForm = () => {
    formik.resetForm(initialState);
    setShowError(false);
    setShowSuccess(false);
  };

  return (
    <>
      <Form onSubmit={formik.handleSubmit}>
        <Row>
          <Col xs="12" lg={6}>
            <Row>
              <Col>
                <FormGroup>
                  <Label className="text-body-large-bold text-secondary-default">
                    {strings.QUANTITY}
                  </Label>
                  <InputGroup className="input-group-alternative" size="sm">
                    <Input
                      className="form-control-alternative"
                      placeholder={strings.QUANTITY_PLACEHOLDER}
                      type="number"
                      maxLength={3}
                      name="quantity"
                      id="quantity"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.quantity}
                    />
                  </InputGroup>
                  {formik.errors.quantity && formik.touched.quantity ? (
                    <span className="text-error text-link-regular">
                      {formik.errors.quantity}
                    </span>
                  ) : null}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <Label className="text-body-large-bold text-secondary-default">
                  {strings.DISCOUNT}
                </Label>
                <FormGroup
                  className="m-0 mx-3 align-items-center"
                  row
                  tag="fieldset"
                  onChange={formik.handleChange}
                >
                  <FormGroup className="custom-radio" tag={Col} check xs={3}>
                    <Input
                      name="discountOption"
                      className="custom-control-input"
                      value={strings.DISCOUNT_OPTIONS.FREE}
                      id={strings.DISCOUNT_OPTIONS.FREE}
                      type="radio"
                      checked={
                        formik.values.discountOption ===
                        strings.DISCOUNT_OPTIONS.FREE
                      }
                      onChange={(e) => {
                        formik.handleChange(e);
                        formik.setFieldValue("discount", 100);
                      }}
                    />
                    <Label
                      check
                      for={strings.DISCOUNT_OPTIONS.FREE}
                      className="custom-control-label"
                    >
                      <span className="text-body-large text-secondary-default ">
                        {strings.DISCOUNT_OPTIONS_LABELS.FREE}
                      </span>
                    </Label>
                  </FormGroup>
                  <FormGroup className="custom-radio" tag={Col} check xs={3}>
                    <Input
                      name="discountOption"
                      className="custom-control-input"
                      value={strings.DISCOUNT_OPTIONS.PERCENTAGE}
                      id={strings.DISCOUNT_OPTIONS.PERCENTAGE}
                      type="radio"
                      checked={
                        formik.values.discountOption ===
                        strings.DISCOUNT_OPTIONS.PERCENTAGE
                      }
                      onChange={formik.handleChange}
                    />
                    <Label
                      check
                      for={strings.DISCOUNT_OPTIONS.PERCENTAGE}
                      className="custom-control-label"
                    >
                      <span className="text-body-large text-secondary-default ">
                        {strings.DISCOUNT_OPTIONS_LABELS.PERCENTAGE}
                      </span>
                    </Label>
                  </FormGroup>
                  <FormGroup tag={Col} xs={6} className="m-0">
                    <InputGroup className="input-group-alternative" size="sm">
                      <Input
                        id="discount"
                        name="discount"
                        className="form-control-alternative"
                        placeholder={strings.DISCOUNT_PLACEHOLDER}
                        type="number"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.discount}
                        disabled={
                          formik.values.discountOption ===
                          strings.DISCOUNT_OPTIONS.FREE
                        }
                      />
                      <InputGroupAddon addonType="append">
                        <InputGroupText>
                          {strings.DISCOUNT_OPTIONS_LABELS.SYMBOL}
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                    {formik.errors.discount && formik.touched.discount ? (
                      <span className="text-error text-link-regular">
                        {formik.errors.discount}
                      </span>
                    ) : null}
                  </FormGroup>
                </FormGroup>
                {formik.errors.discountOption &&
                formik.touched.discountOption ? (
                  <span className="text-error text-link-regular">
                    {formik.errors.discountOption}
                  </span>
                ) : null}
              </Col>
            </Row>
          </Col>
          <Col xs="12" lg={6}>
            <Row>
              <Col>
                <FormGroup>
                  <Label className="text-body-large-bold text-secondary-default">
                    {strings.SERVICE}
                  </Label>
                  <InputGroup className="input-group-alternative" size="sm">
                    <Input
                      className=""
                      placeholder={strings.SERVICE_PLACEHOLDER}
                      type="select"
                      name="serviceId"
                      id="serviceId"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.serviceId}
                    >
                      {
                        <>
                          <option value="">
                            {strings.SERVICE_PLACEHOLDER}
                          </option>
                          {servicesOptions.map((service) => {
                            return (
                              <option key={service.value} value={service.value}>
                                {service.label}
                              </option>
                            );
                          })}
                        </>
                      }
                    </Input>
                  </InputGroup>
                  {formik.errors.serviceId && formik.touched.serviceId ? (
                    <span className="text-error text-link-regular">
                      {formik.errors.serviceId}
                    </span>
                  ) : null}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label className="text-body-large-bold text-secondary-default">
                    {strings.DURATION}
                  </Label>
                  <InputGroup className="input-group-alternative" size="sm">
                    <Input
                      className="form-control-alternative"
                      type="text"
                      name="sd"
                      id="sd"
                      onChange={(e) => {
                        e.preventDefault();
                      }}
                      value={`${
                        formik.values.dateRange?.from
                          ? moment(formik.values.dateRange.from).format(
                              "DD/MM/YYYY"
                            )
                          : ""
                      } ${
                        formik.values.dateRange?.to
                          ? `- ${moment(formik.values.dateRange?.to).format(
                              "DD/MM/YYYY"
                            )}`
                          : ""
                      }`}
                    />
                    <InputGroupAddon addonType="append">
                      <UncontrolledDropdown active>
                        <DropdownToggle
                          nav
                          className="h-100 d-flex align-items-center"
                        >
                          <CalendarIcon
                            width={20}
                            height={20}
                            className="stroke-primary-default"
                          />
                        </DropdownToggle>
                        <DropdownMenu right>
                          <DayPicker
                            style={{ width: "100%" }}
                            mode="range"
                            selected={formik.values.dateRange}
                            onSelect={handleSelectDateRange}
                          />
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </InputGroupAddon>
                  </InputGroup>
                  {formik.errors.dateRange && formik.touched.dateRange ? (
                    <span className="text-error text-link-regular">
                      {formik.errors.dateRange.from
                        ? formik.errors.dateRange.from
                        : formik.errors.dateRange.to
                        ? formik.errors.dateRange.to
                        : ""}
                    </span>
                  ) : null}
                </FormGroup>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="m-0 py-3">
          <Button
            className="btn-noti-primary py-1 px-5"
            type="submit"
            disabled={formik.isSubmitting}
          >
            <Row className="m-0 align-items-center">
              {formik.isSubmitting ? (
                <Spinner size="sm" color="light" className="mr-2" />
              ) : (
                <PlusIcon
                  width={20}
                  height={20}
                  className="stroke-neutro-1 mr-2"
                />
              )}
              <span className="text-button">{strings.SUBMIT}</span>
            </Row>
          </Button>
          <Button color="link" className="py-1 px-5" onClick={handleResetForm}>
            <Row className="m-0 align-items-center">
              <CloseIcon
                width={20}
                height={20}
                className="stroke-primary-default mr-2"
              />
              <span className="text-body-regular text-primary-default">
                {strings.CLEAR}
              </span>
            </Row>
          </Button>
          <Col className="align-items-center d-flex">
            {showError && (
              <CustomMessage
                icon={
                  <CloseIcon width={24} height={24} className="stroke-error" />
                }
                message={strings.ERROR}
                className="m-0"
              />
            )}
            {showSuccess && (
              <CustomMessage
                icon={
                  <CheckIcon
                    width={24}
                    height={24}
                    className="stroke-success"
                  />
                }
                message={strings.SUCCESS}
                className="m-0"
              />
            )}
          </Col>
        </Row>
      </Form>
    </>
  );
};

AddVoucherForm.propTypes = {};

export default AddVoucherForm;
