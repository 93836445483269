const VOUCHERS_VIEW_STRINGS = Object.freeze({
  TITLE: 'Vouchers',
  ADD_VOUCHER_CARD: Object.freeze({
    TITLE: 'Crear Voucher',
    ADD_VOUCHER_FORM: Object.freeze({
      QUANTITY: 'Cantidad de vouchers',
      QUANTITY_PLACEHOLDER: 'Cantidad de vouchers quiere crear',
      MAX_QUANTITY_ERROR: 'La cantidad máxima de vouchers que puede crear es de 100.',
      MIN_QUANTITY_ERROR: 'La cantidad mínima de vouchers que puede crear es de 1.',
      DISCOUNT: 'Descuento',
      DISCOUNT_OPTIONS: Object.freeze({
        FREE: 'GRATIS',
        PERCENTAGE: 'PORCENTUAL',
      }),
      DISCOUNT_OPTIONS_LABELS: Object.freeze({
        FREE: 'Gratis',
        PERCENTAGE: 'Descuento del',
        SYMBOL: '%',
      }),
      DISCOUNT_PLACEHOLDER: 'Porcentaje de descuento',
      DISCOUNT_ERROR: 'El descuento debe ser un número entre 1 y 100.',
      SERVICE: 'Servicio',
      SERVICE_PLACEHOLDER: 'Seleccione un servicio',
      DURATION: 'Duración del voucher',
      SUBMIT: 'Crear',
      CLEAR: 'Limpiar campos',
      REQUIRED: 'Campo obligatorio.',
      ERROR: '¡Hubo un error al crear el voucher!',
      SUCCESS: '¡Voucher creado con éxito!',
    }),
  }),
  VOUCHERS_LIST: Object.freeze({
    TITLE: 'Vouchers creados',
    VOUCHER_LIST_ITEM: Object.freeze({
      SYMBOL: '%',
      VALID: 'Válido',
      SINCE: 'Desde',
      UNTIL: 'Hasta',
    }),
    ERROR: "No tenés ningún voucher cargado."
  })
})

export default VOUCHERS_VIEW_STRINGS;