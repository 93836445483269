import { API_PAY } from "../helpers/fetch";
import useSWR from "swr";

const subscriptionService = {
  useSubscription,
  updateSubscription,
};

const fetcher = ([url, params]) => {
  return API_PAY.get(url, params ? { params } : {});
};
function useSubscription() {
  const { data, error, mutate } = useSWR(["/noticenter/services"], fetcher);

  return {
    services: data?.services,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
}

async function updateSubscription({ name, price, serviceId }) {
  return await API_PAY.put(`/noticenter/services/${serviceId}`, {
    nombre: name,
    precio: price,
  });
}
export default subscriptionService;
