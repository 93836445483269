/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useEffect} from "react";
import ReactDOM from "react-dom";
import {BrowserRouter, Switch} from "react-router-dom";
import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
import {useLocation} from "react-router-dom";
import {AUTH, ROLE, ROLES} from "helpers/consts";
import {SWRConfig} from 'swr';
import {AdminRoute, AuthRoute} from "./routes";
import {AdminLayout, AuthLayout} from "./layouts";
import {AuthProvider} from "./context/auth";
import {PrintProvider} from "./context/print";

const Routes = () => {
  const {pathname} = useLocation();
  const authValue = localStorage.getItem(AUTH) ?? sessionStorage.getItem(AUTH) ?? ''
  const rolValue = localStorage.getItem(ROLE) ?? sessionStorage.getItem(ROLE) ?? ''

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      {authValue ? (
        rolValue === ROLES.ADMIN
          ? <AdminRoute component={AdminLayout}/>
          : <AuthRoute component={AuthLayout}/>
      ) : (
        <AuthRoute component={AuthLayout}/>
      )}
    </>
  );
};

const App = () => (
  <SWRConfig
    value={{
      refreshInterval: 150000,
      errorRetryInterval: 150000,
      errorRetryCount: 1
    }}
  >
    <BrowserRouter>
      <AuthProvider>
        <PrintProvider>
          <Switch>
            <Routes/>
          </Switch>
        </PrintProvider>
      </AuthProvider>
    </BrowserRouter>
  </SWRConfig>

);

ReactDOM.render(<App/>, document.getElementById("root"));
