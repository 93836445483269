import React from 'react';
import HomeHeader from "../../components/Header";
import USER_VIEW_STRINGS from "./assets/strings";
import usersService from "../../services/users.service";
import {useParams} from "react-router-dom";
import UserDetailsHeader from "./components/UserDetailsHeader";
import {Container} from "reactstrap";
import UserSubscriptions from "./components/UserSubscriptions";
import UserLastLogin from "./components/UserLastLogin";

const UserDetails = () => {
  const { id } = useParams();
  const {
    data: user,
    isLoading,
    isError,
    error,
    mutate,
  } = usersService.useUser({ userId: id });

  return (
    <>
      <HomeHeader headerText={USER_VIEW_STRINGS.USER_DETAILS.HEADER_TEXT} canGoBack />
      <Container>
        <UserDetailsHeader
          user={user}
          mutate={mutate}
        />
        <UserSubscriptions services={user?.services}/>
        <UserLastLogin
          lastAccessedDate={user?.lastAccessedAt}
        />
      </Container>
    </>
  );
};

export default UserDetails;
