const TABLE_OPTIONS_STRINGS = Object.freeze({
  SEARCH_OPTION: 'Buscar',
  SEARCH_SUBMIT: 'Buscar',
  CLEAR_SEARCH: 'Limpiar campos',
  USERS_SEARCH: Object.freeze({
    USER_OR_EMAIL: 'Usuario / E-mail',
    USER_OR_EMAIL_PLACEHOLDER: 'nombre de usuario ó E-mail',
    REQUIRED: 'Campo obligatorio',
    USER_STATUS: 'Estado',
    USER_STATUS_PLACEHOLDER: 'Seleccione un estado',
    USER_STATUS_OPTIONS: Object.freeze({
      ACTIVE: 'Activo',
      INACTIVE: 'Con servicios vencidos',
      PENDING: 'Pendiente de pago',
      ALL: 'Todos'
    }),
    SUBMIT: 'Buscar',
    CLEAR: 'Limpiar campos',
  })
})

export default TABLE_OPTIONS_STRINGS;
