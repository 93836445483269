import React, {useContext} from "react";
import {Link, useHistory} from "react-router-dom";
import {
  Navbar,
  Container,
  Row, NavbarBrand, Nav, NavItem, Button,
} from "reactstrap";
import auth from "../../../context/auth";
import { ReactComponent as BrandIcon } from "assets/img/icons/logo/notiexpress-logo-white.svg";
import { ReactComponent as LogOutIcon } from "assets/img/icons/notiexpress/log-out-icon.svg";

/**
 * Navbar for Admin user.
 * @returns {JSX.Element}
 * @constructor
 */
const AdminNavbar = () => {
  const history  = useHistory()
  const { actions: { loggedOut } } = useContext(auth)
  const handleLogOut = () => {
    loggedOut()
    history.push('/auth/login')
  }
  return (
    <>
      <Navbar
        className="navbar-top navbar-dark d-none d-md-block bg-primary-default"
        expand="md"
        id="navbar-main"
      >
        <Container className="h-100" fluid>
          <NavbarBrand to="/" tag={Link}>
            <BrandIcon  />
          </NavbarBrand>
          <Row>
            <Nav navbar>
              <NavItem
                className="nav-item"
              >
                <Button
                  type="button"
                  color="link"
                  onClick={handleLogOut}
                >
                  <LogOutIcon className="stroke-neutro-1"/>
                </Button>
              </NavItem>
            </Nav>
          </Row>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
