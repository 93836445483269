/**
 * Function to parse an object to a query string
 * Filters empty properties ie. {key: undefined, key2: "", key3: null}
 * @param {Object} params - Object to be parsed
 * @returns {string} a query string with the "key1=value&key2=value2" format
 */
export const parseObjectToQS = (params) => {
  try {
    return Object.keys(params)
        .filter((key) => isValidQS(params[key]))
        .map((key) => `${key}=${params[key]}`)
        .join("&");
  } catch (_) {
    // ignore
  }
  return "";
};

/**
 * Wrapper for JSON parse function
 * @param {string} x - json string
 * @returns - parsed object if argument was valid (empty object {} if wasn't valid)
 */
export const parseJson = (x) => {
  try {
    return JSON.parse(x);
  } catch (error) {
    // ignore
  }
  return {};
};

/**
 * Function to group by "key" a given array
 * @param {Array<Object>} list - Array to be grouped
 * @param {string} key - key for the "group by" operation
 * @param {boolean} omitKey - flag to include or not the given key into the result object
 * @returns - Object with arrays grouped by key
 */
export const groupByKey = (list, key, omitKey = false) => {
  try {
    return list.reduce(
        (hash, {[key]: value, ...rest}) => ({
          ...hash,
          [value]: (hash[value] || []).concat(
              omitKey ? {...rest} : {[key]: value, ...rest}
          ),
        }),
        {}
    );
  } catch {
    // console.log(error.message);
  }

};

/**
 * Function to validate a query string value
 * @param {any} value
 * @returns - a boolean that indicates if the value is a valid query string
 */
const isValidQS = (value) =>
  (typeof value === "string" && !isEmptyString(value)) ||
  typeof value === "number" ||
  typeof value === "boolean";

const isEmptyString = (value) => value.trim().length === 0;

/**
 * Create a new object based on given object, without properties listed in keys array.
 * @param {Array<string>} keys Array of properties to remove.
 * @param object The given object.
 * @returns {Object}
 */
export const createNewObjectWithoutProperties = ({keys, object}) => {
  let newObject = {};
  if (keys) {
    newObject = Object.fromEntries(
        Object.entries(object).filter(([key,]) => {
              return keys?.every(inputKey => inputKey !== key)
            }
        )
    )
  }
  return newObject;
}

