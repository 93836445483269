import {API_NOTIF} from "../helpers/fetch";
import useSWR from "swr";

const newsletterService = {
  useNewsletters,
  sendAndSaveNewsletter
}
const fetcher = ([ url, params]) => {
  return API_NOTIF.get(url, params ? { params } : null)
}

function useNewsletters() {
  const { data, error } = useSWR(['/noticenter/newsletter'], fetcher)

  return {
    newsletters: data,
    isLoading: !error && !data,
    isError: error
  }
}

function sendAndSaveNewsletter({ title, subtitle, subject, body , to = [], receivers, description = "" }) {
  return API_NOTIF.post('/noticenter/newsletter', {
    title,
    subtitle,
    subject,
    body,
    description,
    to,
    receivers
  })
}

export default newsletterService;
