import React from 'react';
import HomeHeader from "../../components/Header";
import SETTINGS_VIEW_STRINGS from "./assets/strings";
import {Card, CardBody, CardHeader, Col, Container} from "reactstrap";
import AddDefaultHolidays from "./components/AddDefaultHolydays";
import DefaultHolidaysList from "./components/DefaultHolydaysList";
import defaultNonWorkingDaysService from "../../services/defaultNonWorkingDays.service";


const HolidaysSettingsView = () => {
  const strings = SETTINGS_VIEW_STRINGS.HOLIDAYS_VIEW;
  const {
    data,
    isLoading,
    isError,
    mutate
  } = defaultNonWorkingDaysService.useDefaultNonWorkingDays();

  return (
    <>
      <HomeHeader headerText={strings.TITLE} canGoBack />
      <Container>
        <Card className="shadow-card">
          <CardHeader>
            <span className="text-heading-2">{strings.SUBTITLE}</span>
          </CardHeader>
          <CardBody>
            <Col>
              <AddDefaultHolidays mutate={mutate}/>
              <DefaultHolidaysList
                holidays={data}
                isLoading={isLoading}
                isError={isError}
              />
            </Col>
          </CardBody>
        </Card>
      </Container>
    </>
  );
};

HolidaysSettingsView.propTypes = {};

export default HolidaysSettingsView;
