import {API} from "../helpers/fetch";
import useSWR from "swr";

const defaultNonWorkingDaysService = {
  useDefaultNonWorkingDays,
  addDefaultNonWorkingDays,
  deleteDefaultNonWorkingDays,
}
const fetcher = ([url, params]) => {
  return API.get(url, params ? { params } : null);
}
function useDefaultNonWorkingDays() {
  const { data, error, mutate } = useSWR(['/noticenter/defaultNonWorkingDays'], fetcher);

  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
    mutate
  }
}

function addDefaultNonWorkingDays({initDate, endDate, jurisdictions, description, repeat}) {
  const bodyRequest = jurisdictions.map((jurisdiction) => ({
    initDate, ...endDate && {endDate}, jurisdictionId: jurisdiction.value, description, repeat
  }));
  return API.post(`/noticenter/defaultNonWorkingDays`, bodyRequest);
}

function deleteDefaultNonWorkingDays({id}) {
  return API.delete(`/noticenter/defaultNonWorkingDays/${id}`);
}
export default defaultNonWorkingDaysService;