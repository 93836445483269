import moment from "moment";

const lastAccessedColumns = [
  {
    Header: "Orden",
    accessor: (row, i) => i + 1,
  },
  {
    Header: "Fecha",
    accessor: (date) => moment(date).format("DD/MM/YYYY"),
  },
  {
    Header: "Hora",
    accessor: (date) => moment(date).format("HH:mm"),
  },
];

export default lastAccessedColumns;
