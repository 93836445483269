import React from 'react';
import CustomModal from "../../../../components/Modals";
import COMUNICATIONS_STRINGS from "../../assets/strings";
import {Col, ModalBody, Row} from "reactstrap";
import {ReactComponent as LogoIcon} from "assets/img/icons/logo/notiexpress-logo-blue.svg";
import {ReactComponent as LogoWhiteIcon} from "assets/img/icons/logo/notiexpress-logo-white.svg";
import {ReactComponent as ZapIcon} from "assets/img/icons/notiexpress/zap-icon.svg";

const STRINGS = COMUNICATIONS_STRINGS.PREVIEW;
interface IPreviewNewsletterModalProps {
  isOpen: boolean;
  toggle: () => void;
  newsletter: any;
}
const PreviewNewsletterModal = ({isOpen, toggle, newsletter}: IPreviewNewsletterModalProps) => {
  return (
    <>
      <CustomModal
        isOpen={isOpen}
        toggle={toggle}
        title={STRINGS.TITLE}
        className="w-75"
      >
        <ModalBody className="pb-5 px-5 pt-1 ">
          <Col className="border border-dark">
            <Row className="align-items-center justify-content-center bg-white" style={{ height: "84px"}}>
              <LogoIcon />
            </Row>
            <Row className="m-0 p-2">
              <Col className="p-3">
                <div dangerouslySetInnerHTML={{__html: newsletter.body}}/>
                <Row className="align-items-center justify-content-center bg-secondary-default rounded" style={{ height: "84px"}}>
                  <LogoWhiteIcon />
                </Row>
              </Col>
            </Row>
            <Row className="align-items-center justify-content-center bg-primary-default" style={{ height: "84px"}}>
              <ZapIcon width={24} height={24} className="stroke-white fill-white" />
            </Row>
          </Col>
        </ModalBody>
      </CustomModal>
    </>
  );
};

export default PreviewNewsletterModal;
