import React from 'react';
import CustomModal from "../CustomModal";
import {Button, Col, ModalBody, Row} from "reactstrap";
import {create} from "react-modal-promise";
import STRINGS from "./assets/strings";
interface DisableServiceModalProps {
  isOpen: boolean;
  onReject: () => void;
  onResolve: () => void;
}
const DisableServiceModal = ({isOpen, onReject, onResolve}: DisableServiceModalProps) => {
  return (
    <CustomModal
      title={STRINGS.title}
      isOpen={isOpen}
      toggle={() => onReject()}
      className={"w-75"}
    >
      <ModalBody>
        <Row>
          <Col>
            <p className="text-heading-2">{STRINGS.subtitle}</p>
            <p className="">{STRINGS.description}</p>
          </Col>
        </Row>
        <Row className="m-0 py-3">
          <Button
            className="btn-outline-noti-secondary py-1 px-5"
            onClick={onReject}
          >
            <Row className="m-0 align-items-center">
              {STRINGS.cancel}
            </Row>
          </Button>
          <Button
            className="btn-noti-primary py-1 px-5"
            onClick={onResolve}
          >
            <Row className="m-0 align-items-center">
              <span className="text-button">{STRINGS.accept}</span>
            </Row>
          </Button>
        </Row>
      </ModalBody>
    </CustomModal>
  );
};

const disableServiceModal = create(DisableServiceModal);
export default disableServiceModal;
