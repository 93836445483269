import React from 'react';
import Sidebar from "../../components/Sidebar/Sidebar";
import {LAYOUTS} from "../../helpers/consts";
import {AdminNavbar} from "../../components/Navbars";
import {Container as ModalContainer} from 'react-modal-promise'
import {Redirect, Route, Switch} from "react-router-dom";
import routes from "routes";

const AdminLayout = () => {

  const adminRoutes = routes.filter((route) => route.layout === LAYOUTS.ADMIN);
  const getRoutes = (adminRoutes) => {
    return adminRoutes
      .map((route, index) => {
        return (
          <Route
            key={index}
            path={route.layout + route.path}
            component={route.component}
            exact={route.exact}
          />
        );
      })
  }
  return (
    <>
      <Sidebar
        layout={LAYOUTS.ADMIN}
        routes={adminRoutes}
      />
      <div className="main-content">
        <AdminNavbar/>
        {/* Page content */}
        <div className="container-admin">
          <Switch>
            {getRoutes(adminRoutes)}
            <Redirect
              from="*"
              to={`/admin/users`}
            />
          </Switch>
        </div>
        <ModalContainer />
      </div>
    </>
  );
};

export default AdminLayout;
