import React from 'react';
import CustomModal from "../../../../components/Modals";
import SETTINGS_VIEW_STRINGS from "../../assets/strings";
import {
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon, InputGroupText,
  Label,
  ModalBody,
  Row,
  Spinner
} from "reactstrap";
import * as Yup from "yup";
import {useFormik} from "formik";
import subscriptionService from "../../../../services/subscription.service";
import {ReactComponent as DollarIcon} from "assets/img/icons/notiexpress/dollar-icon.svg";
import {ReactComponent as UserCheckIcon} from "assets/img/icons/notiexpress/user-check-icon.svg";
import { useSWRConfig } from "swr";

const strings = SETTINGS_VIEW_STRINGS.SUBSCRIPTIONS_VIEW.MODIFY_SUBSCRIPTION;
const modifySubscriptionSchema = Yup.object().shape({
  name: Yup.string()
    .required(strings.FORM.REQUIRED),
  price: Yup.number()
    .required(strings.FORM.REQUIRED),
});
const ModifySubscriptionModal = ({isOpen, toggle, subscription} : { isOpen: boolean, toggle: () => void, subscription: any}) => {
  const { mutate } = useSWRConfig();

  const initialValues = {
    name: subscription.nombre,
    price: subscription.precio,
  }
  const handleSubmit = async (values : any) => {
    formik.setSubmitting(true);
    try {
      await subscriptionService.updateSubscription({
        serviceId: subscription._id,
        name: values.name,
        price: values.price,
      });
      toggle();
    } catch (error) {
      //console.log(error);
    } finally {
      formik.setSubmitting(false);
      await mutate(["/noticenter/services"])
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: modifySubscriptionSchema,
    onSubmit: handleSubmit,
  });
  const Field = ({label, value}: {label: string, value: string}) => (
    <Col>
      <p className="text-body-large-bold text-secondary-default">{label}</p>
      <p className="text-body-large">{value}</p>
    </Col>
  );

  return (
    <>
      <CustomModal
        title={strings.TITLE}
        isOpen={isOpen}
        toggle={() => toggle()}
        className="w-75"
      >
        <ModalBody>
          <Form onSubmit={formik.handleSubmit}>
            <Card className="shadow-item">
              <Row className="p-4 m-0 px-3">
                <span className="text-heading-2">Información del servicio</span>
              </Row>
              <Row className="m-0">
                <Col xs={12} md={8} className="p-3">
                  <Field label={strings.FORM.NAME} value={subscription.nombre}/>
                </Col>
                <Col xs={12} md={4} className="p-3">
                  <Field label={strings.FORM.CURRENT_PRICE} value={"$" + subscription.precio}/>
                </Col>
              </Row>
            </Card>
            <Row className="py-3">
              <Col xs={12} md={6}>
                <FormGroup>
                  <Label for="name" className="text-body-large-bold text-secondary-default">
                    {strings.FORM.NAME}
                  </Label>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText className="bg-white border-right-0">
                        <UserCheckIcon width={20} height={20} className="stroke-primary-default"/>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      className="form-control-alternative"
                      type="text"
                      name="name"
                      placeholder={strings.FORM.NAME_PLACEHOLDER}
                      id="name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.name}
                    />
                  </InputGroup>
                  {
                    formik.touched.name && formik.errors.name ? (
                      <span className="text-body-small text-error">
                        {formik.errors.name.toString()}
                      </span>
                    ) : null
                  }
                </FormGroup>
              </Col>
              <Col xs={12} md={6}>
                <FormGroup>
                  <Label for="price" className="text-body-large-bold text-secondary-default">
                    {strings.FORM.NEW_PRICE}
                  </Label>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText className="bg-white border-right-0">
                        <DollarIcon width={20} height={20} className="stroke-primary-default"/>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      className="form-control-alternative"
                      type="text"
                      name="price"
                      placeholder={strings.FORM.PRICE_PLACEHOLDER}
                      id="price"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.price}
                    />
                  </InputGroup>
                  {
                    formik.touched.price && formik.errors.price ? (
                      <span className="text-body-small text-error">
                        {formik.errors.price.toString()}
                      </span>
                    ) : null
                  }
                </FormGroup>
              </Col>
            </Row>
            <Row className="m-0 align-items-center">
              <Button
                className="btn-noti-primary py-1 px-5"
                type="submit"
                disabled={formik.isSubmitting}
              >
               <Row className="align-items-center">
                 {
                    formik.isSubmitting ? (
                      <Spinner
                        className="mr-2"
                        size="sm"
                        color="light"
                      />
                    ) : null
                 }
                 <span className="text-button">{strings.FORM.SUBMIT}</span>
               </Row>
              </Button>
            </Row>
          </Form>
        </ModalBody>
      </CustomModal>
    </>
  );
};

ModifySubscriptionModal.propTypes = {

};

export default ModifySubscriptionModal;
