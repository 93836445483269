import {API} from "../helpers/fetch";
import {ID, ROLE, AUTH, EMAIL, NAME, LAST_NAME} from "../helpers/consts";

export const authService = {
  login,
  logout,
  verifyUser
};

async function login({email, password, keepSession}) {
  const response = await API.post('/auth/signin', {email, password});
  if (response && response?.user?.habilitado) {
    // store user details and basic auth credentials in local storage
    // to keep user logged in between page refreshes
    if (keepSession) {
      localStorage.setItem(ID, response.user?.id);
      localStorage.setItem(EMAIL, response.user?.email);
      localStorage.setItem(ROLE, response.user?.rol);
      localStorage.setItem(AUTH, response?.token);
      localStorage.setItem(NAME, response?.user?.name);
      localStorage.setItem(LAST_NAME, response?.user?.lastName)
    } else {
      sessionStorage.setItem(ID, response.user?.id);
      sessionStorage.setItem(EMAIL, response.user?.email);
      sessionStorage.setItem(ROLE, response.user?.rol);
      sessionStorage.setItem(AUTH, response?.token);
      sessionStorage.setItem(NAME, response?.user?.name);
      sessionStorage.setItem(LAST_NAME, response?.user?.lastName)
    }
  }
  return response;
}

function verifyUser() {
  return API.get('user/me')
}

function logout() {
// remove user from local storage to log user out
  localStorage.removeItem(ID);
  localStorage.removeItem(EMAIL);
  localStorage.removeItem(ROLE);
  localStorage.removeItem(AUTH);
  localStorage.removeItem(NAME);
  localStorage.removeItem(LAST_NAME);
  sessionStorage.removeItem(ID);
  sessionStorage.removeItem(EMAIL);
  sessionStorage.removeItem(ROLE);
  sessionStorage.removeItem(AUTH);
  sessionStorage.removeItem(NAME);
  sessionStorage.removeItem(LAST_NAME);
}