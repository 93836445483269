import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {parseObjectToQS} from "../../helpers/utils";

const useSearchFilters = ({ newParams }) => {
  const history = useHistory();
  const currentParams = new URLSearchParams(history.location.search);
  const [params, setParams] = useState({
    ...Object.fromEntries(currentParams.entries()),
    ...newParams
  });

  useEffect(() => {
    history.push(`${history.location.pathname}?${parseObjectToQS(params)}`);
  }, [params]);

  useEffect(() => {
    const currentParams = new URLSearchParams(history.location.search);
    setParams({
      ...params,
      ...Object.fromEntries(currentParams.entries()),
    });
  }, [history.location.search]);

  const resetSearch = () => {
    setParams({
      ...newParams
    });
  };


  return {params, setParams, resetSearch};
}

export default useSearchFilters;