import React from "react";
import HomeHeader from "../../components/Header";
import SETTINGS_VIEW_STRINGS from "./assets/strings";
import { Col, Container } from "reactstrap";
import SettingsItemList from "./components/SettingsItemList";

const SettingsView = () => {
  const strings = SETTINGS_VIEW_STRINGS;
  return (
    <>
      <HomeHeader headerText={strings.TITLE} />
      <Container>
        <Col>
          {strings.OPTIONS.map((option, index) => {
            return <SettingsItemList key={index} setting={option} />;
          })}
        </Col>
      </Container>
    </>
  );
};

export default SettingsView;
