import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import SETTINGS_VIEW_STRINGS from "../../assets/strings";
import {ReactComponent as EllipsisVerticalIcon} from 'assets/img/icons/notiexpress/ellipsis-vertical-icon.svg';
import ModifySubscriptionModal from "../ModifySubscriptionModal";

const SubscriptionOptionDropdown = ({subscription} : {subscription: any}) => {
  const  [ isOpen, setIsOpen ] = useState(false);

  const [isOpenModifyModal, setIsOpenModifyModal] = useState(false);
  //const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);

  const toggle = () => setIsOpen(!isOpen);
  const strings = SETTINGS_VIEW_STRINGS.SUBSCRIPTIONS_VIEW.TABLE;
  return (
    <>
      <Dropdown
        className="m-0"
        isOpen={isOpen}
        toggle={() => toggle()}
        onClick={(e) => e.stopPropagation()}
        style={{width: 'fit-content'}}
      >
        <DropdownToggle
          className="m-0 p-0 px-2 border-0 w-100 btn-link text-neutro-2"
          tag={Button}
          onClick={(e) => e.preventDefault()}
        >
          <EllipsisVerticalIcon width={20} height={20} className="stroke-primary-default"/>
        </DropdownToggle>
        <DropdownMenu tag="ul" className="dropdown-menu-arrow" right>
          <DropdownItem href="#" onClick={() => setIsOpenModifyModal(true)}>
            {strings.ACTIONS.EDIT}
          </DropdownItem>
          {/*<DropdownItem href="#" onClick={() => setIsOpenDeleteModal(true)}>
            {strings.ACTIONS.DELETE}
          </DropdownItem>*/}
        </DropdownMenu>
      </Dropdown>
      <ModifySubscriptionModal
        isOpen={isOpenModifyModal}
        toggle={() => setIsOpenModifyModal(!isOpenModifyModal)}
        subscription={subscription}
      />
    </>
  );
};

SubscriptionOptionDropdown.propTypes = {
  subscription: PropTypes.object.isRequired
};

export default SubscriptionOptionDropdown;
