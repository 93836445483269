import React, {useState} from 'react';
import {Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import {ReactComponent as EllipsisVerticalIcon} from 'assets/img/icons/notiexpress/ellipsis-vertical-icon.svg';
import SETTINGS_VIEW_STRINGS from "../../assets/strings";
import DeleteHolidayModal from "../DeleteHolidayModal/DeleteHolidayModal";

const HolidayOptionDropdown = ({holiday} : { holiday : any}) => {
  const [isOpen, setIsOpen] = useState(false);

  //const [isOpenModifyModal, setIsOpenModifyModal] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const strings = SETTINGS_VIEW_STRINGS.HOLIDAYS_VIEW.TABLE;
  const toggle = () => setIsOpen(!isOpen);
  return (
    <>
      <Dropdown
        className="m-0 w-100"
        isOpen={isOpen}
        toggle={toggle}
        onClick={(e) => e.stopPropagation()}
      >
        <DropdownToggle
          className="m-0 p-0 px-2 border-0 w-100 btn-link text-neutro-2"
          tag={Button}
          onClick={(e) => e.preventDefault()}
        >
          <EllipsisVerticalIcon width={20} height={20} className="stroke-primary-default"/>
        </DropdownToggle>
        <DropdownMenu tag="ul" className="dropdown-menu-arrow" right>
          {/*<DropdownItem href="#" onClick={() => setIsOpenModifyModal(true)}>
            {strings.ACTIONS_OPTIONS.EDIT}
          </DropdownItem>*/}
          <DropdownItem href="#" onClick={() => setIsOpenDeleteModal(true)}>
            {strings.ACTIONS_OPTIONS.DELETE}
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
      {/*<ModifyHolidayModal
        isOpen={isOpenModifyModal}
        toggle={() => setIsOpenModifyModal(!isOpenModifyModal)}
        holiday={holiday}
      />*/}
      <DeleteHolidayModal
        holidayId={holiday.id}
        isOpen={isOpenDeleteModal}
        toggle={() => setIsOpenDeleteModal(!isOpenDeleteModal)}
      />
    </>
  );
};

HolidayOptionDropdown.propTypes = {

};

export default HolidayOptionDropdown;
