import useSWR from "swr";
import {API} from "../helpers/fetch";

const generalService = {
  useJurisdictions,
  enableService,
  disableService
}
const fetch =  ([url, params]) => API.get(url, params ? { params } : {})
function useJurisdictions() {
  const { data, error, mutate } = useSWR(['/jurisdiction'], fetch)
  return {
    jurisdictions: data,
    isLoading: !error && !data,
    isError: error,
    mutate
  }
}

function enableService() {
  return API.put(`/noticenter/scraper/enable`)
}
function disableService() {
  return API.put(`/noticenter/scraper/disable`)
}

export default generalService