import React from "react";
import PropTypes from "prop-types";
import { Row, Spinner } from "reactstrap";
import VOUCHERS_VIEW_STRINGS from "../../assets/strings";
import { NOTIEXPRESS_COLORS } from "../../../../helpers/consts";
import VoucherListItem from "../VoucherListItem";

const VouchersList = ({ vouchers, isError, isLoading, children }) => {
  const strings = VOUCHERS_VIEW_STRINGS.VOUCHERS_LIST;

  return (
    <>
      <div className="d-flex  pb-4 pt-5">
        <h1 className="text-heading-2">{strings.TITLE}</h1>
        {children}
      </div>
      {isLoading ? (
        <Row className="align-items-center justify-content-center">
          <Spinner
            size="lg"
            color={NOTIEXPRESS_COLORS.primaryDefault}
            className="mx-auto"
          />
        </Row>
      ) : (
        <>
          {isError ? (
            <Row className="align-items-center justify-content-center">
              <h3 className="text-heading-3 py-3">{strings.ERROR}</h3>
            </Row>
          ) : (
            vouchers?.docs.map((voucher, index) => (
              <VoucherListItem voucher={voucher} key={index} />
            ))
          )}
        </>
      )}
    </>
  );
};

VouchersList.propTypes = {
  vouchers: PropTypes.object,
  isError: PropTypes.bool,
  isLoading: PropTypes.bool,
  children: PropTypes.node,
};

export default VouchersList;
