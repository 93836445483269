import {ACTIONS} from "../assets/const";
import {useHistory} from "react-router-dom";

export const useDispatch = ({dispatch}) => {
  const history = useHistory();

  function loggedIn(data) {
    dispatch({ type: ACTIONS.USER_LOGGEDIN, payload: data });
  }

  function reloadedUser({user}) {
    dispatch({ type: ACTIONS.USER_RELOADED, payload: user });
  }

  function sessionExpired() {
    dispatch({ type: ACTIONS.USER_SESSION_EXPIRED });
    localStorage.clear();
    sessionStorage.clear();
    history.push("/auth/login");
  }
  function loggedOut() {
    dispatch({ type: ACTIONS.USER_LOGGEDOUT });
    localStorage.clear();
    sessionStorage.clear();
    history.push("/auth/login");
  }

  return {
    loggedIn,
    loggedOut,
    reloadedUser,
    sessionExpired,
  }
}