import React from "react";
import SETTINGS_VIEW_STRINGS from "./strings";
import moment from "moment";
import { ReactComponent as EllipsisVerticalIcon } from "assets/img/icons/notiexpress/ellipsis-vertical-icon.svg";
import HolidayOptionDropdown from "../components/HolidayOptionDropdown";

const strings = SETTINGS_VIEW_STRINGS.HOLIDAYS_VIEW.TABLE;
const OptionIcon = strings.ACTIONS;
const holidaysColumns = [
  {
    Header: strings.DATE,
    accessor: ({initDate,endDate}) => (
      <span className="text-body-regular">{
        `${moment(initDate).format("DD/MM/YYYY")}` + (endDate ? ` - ${moment(endDate).format("DD/MM/YYYY")}` : '')
      }</span>
    )
  },
  {
    Header: strings.JURISDICTION,
    accessor: ({Jurisdiction}) => (
      <span className="text-body-regular">{Jurisdiction?.name ?? ''}</span>
    )
  },
  {
    Header: strings.MOTIVE,
    accessor: ({description}) => (
      <span className="text-body-regular">{description ?? ''}</span>
    )
  },
  {
    Header: 'Repetir',
    accessor: ({ repeat }) => {
      switch (repeat) {
        case 'UNIQUE':
          return <span className="text-body-regular">Único</span>;
        case 'YEARLY':
          return <span className="text-body-regular">Anualmente</span>;
        case 'MONTHLY':
          return <span className="text-body-regular">Mensualmente</span>;
        case 'WEEKLY':
          return <span className="text-body-regular">Semanalmente</span>;
        default:
          return <span className="text-body-regular">-</span>;
      }
    },
  },
  {
    Header: () => <OptionIcon width={20} height={20} className="stroke-secondary-default" />,
    accessor: 'actions',
    Cell: ({row}) => (
      <HolidayOptionDropdown holiday={row.original} />
    )
  }
];

export default holidaysColumns;