import React from 'react';
import {Route} from "react-router-dom";

const AuthRoute = ({component: Component, ...rest}) => {

  const renderComponent = (props) => (
    <Component {...props} />
  );

  return (
    <>
      <Route
        {...rest}
        render={renderComponent}
      />
    </>
  );
};

export default AuthRoute;
