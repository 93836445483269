import React from 'react';
import SETTINGS_VIEW_STRINGS from "./strings";
import { ReactComponent as EllipsisVerticalIcon } from "assets/img/icons/notiexpress/ellipsis-vertical-icon.svg";
import SubscriptionOptionDropdown from "../components/SubscriptionOptionDropdown";


const strings = SETTINGS_VIEW_STRINGS.SUBSCRIPTIONS_VIEW.TABLE.HEADERS;
const OptionsIcon = strings.ACTIONS;
const subscriptionsColumns = [
  {
    Header: strings.NAME,
    accessor: (row) => (
      <span className="text-body-regular">{`${row?.nombre}`}</span>
    )
  },
  {
    Header: strings.PRICE,
    accessor: (row) => (
      <span className="text-body-regular">{`$${row?.precio}`}</span>
    )
  },
  {
    Header: () => (<p className="text-center m-0">
      <OptionsIcon width={20} height={20} className="stroke-secondary-default"/>
    </p>),
    accessor: "_id",
    Cell: ({ row }) => (
      <SubscriptionOptionDropdown subscription={row.original}/>
    )
  }
];

export default subscriptionsColumns;