const COMUNICATIONS_STRINGS = Object.freeze({
  TITLE: 'Comunicados',
  ADD_NEWSLETTER: Object.freeze({
    TITLE: 'Crear comunicado',
    FORM: Object.freeze({
      TITLE: 'Título',
      TITLE_PLACEHOLDER: 'Título del comunicado',
      PREVIEW: 'Vista previa',
      SUBMIT: 'Enviar',
      TO:  Object.freeze({
        LABEL: 'Destinatarios',
        PLACEHOLDER: 'usuario@gmail.com',
        OPTIONS: Object.freeze({
          ALL: 'all',
          PENDING: 'subscribers pending',
          UP_TO_DATE: 'subscribers to day',
          CUSTOM: 'user',
        }),
        OPTIONS_LABELS: Object.freeze({
          ALL: 'Todos los usuarios',
          PENDING: 'Usuarios con pagos pendientes',
          UP_TO_DATE: 'Usuarios con pagos al día',
          CUSTOM: 'Usuario',
        }),
      }),
      ERROR: "¡Hubo un error al enviar el comunicado!",
      SUCCESS: "¡El comunicado se envió correctamente!"
    }),
  }),
  TABLE: Object.freeze({
    TITLE: 'Últimos comunicados',
    COLUMNS: Object.freeze({
      TITLE: 'Título',
      DATE: 'Fecha y Hora',
      STATUS: 'Estado',
      ACTIONS: 'Acciones',
    }),
    NO_DATA: 'No tenés ningún comunicado enviado.',
  }),
  PREVIEW: Object.freeze({
    TITLE: 'Vista previa',
  },),
});

export default COMUNICATIONS_STRINGS;