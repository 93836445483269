import { jsPDF as JsPDF } from "jspdf";
import autoTable from 'jspdf-autotable'
import { IPDFSettings } from "./types";

export default function usePDF({ pdfSettings }: { pdfSettings: IPDFSettings }) {
  const { head, details, body, document, title, tableId } = pdfSettings;

  const doc = new JsPDF();

  doc.text(title ?? "PDF", 14, 16);

  function downloadPDF(filename: string) {
    if (tableId) {
      autoTable(doc, { html: `#${tableId}` });
    }
    doc.save(filename);
  }

  autoTable(doc, {
    head: head ?? [[]],
    body: body ?? [[]],
    margin: { top: 20 },
  })

  return {
    base64File: doc.output("bloburi"),
    document: doc,
    downloadPDF
  };
}