import { API_PAY } from "../helpers/fetch";
import useSWR from "swr";

const vouchersService = {
  useServices,
  useVouchers,
  addVoucher,
};

const fetcher = ([url, params]) => {
  return API_PAY.get(url, params ? { params } : {});
};

function useVouchers({page = 1, limit = 10}) {
  const { data, error, mutate } = useSWR([`/noticenter/voucher?page=${page}&limit=${limit}`], fetcher);
  return {
    vouchers: data?.vouchers ?? [],
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
}

function useServices() {
  const { data, error } = useSWR(["/noticenter/services"], fetcher);
  return {
    services: data,
    isLoading: !error && !data,
    isError: error,
  };
}

function addVoucher({ amount, countAvailable, startDate, endDate, service }) {
  return API_PAY.post("/noticenter/voucher", {
    amount,
    countAvailable,
    startDate,
    endDate,
    service,
  });
}

export default vouchersService;
