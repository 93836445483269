import {ReactComponent as CalendarIcon} from 'assets/img/icons/notiexpress/calendar-icon.svg';
import {ReactComponent as UserCheckIcon} from 'assets/img/icons/notiexpress/user-check-icon.svg';
import {ReactComponent as OptionsIcon} from 'assets/img/icons/notiexpress/grid-icon.svg';
import {ReactComponent as CpuIcon} from 'assets/img/icons/notiexpress/cpu.svg';

const SETTINGS_VIEW_STRINGS = Object.freeze({
  TITLE: "Configuración",
  OPTIONS: [
    {
      title: "Días Inhábiles",
      icon: CalendarIcon,
      link: "/admin/settings/holidays"
    },
    {
      title: "Subscripciones",
      icon: UserCheckIcon,
      link: "/admin/settings/subscriptions"
    },
    {
      title: "Servicios",
      icon: CpuIcon,
      link: "/admin/settings/services"
    }
  ],
  HOLIDAYS_VIEW: Object.freeze({
    TITLE: "Días Inhábiles",
    SUBTITLE: "Agregar días inhábiles",
    MDDiFY_HOLIDAY: "Modificar día inhábil",
    FORM: Object.freeze({
      DATE: "Fecha / Rango *",
      DATE_PLACEHOLDER: "Seleccione una fecha o rango",
      JURISDICTION: "Jurisdicción *",
      JURISDICTION_PLACEHOLDER: "Seleccione una jurisdicción",
      MOTIVE: "Motivo *",
      MOTIVE_PLACEHOLDER: "Indique un motivo",
      SUBMIT: "Agregar",
      SUBMIT_EDIT: "Modificar",
      CLEAR: "Limpiar campos",
      REQUIRED: "Campo requerido",
      ERROR: "¡Error al agregar día inhábil!",
      SUCCESS: "¡Día inhábil agregado con éxito!",
    }),
    TABLE: Object.freeze({
      DATE: "Fecha / Rango",
      JURISDICTION: "Jurisdicción",
      MOTIVE: "Motivo",
      REPEAT: "Repetir",
      NO_DATA: "No hay días inhábiles registrados",
      ACTIONS: OptionsIcon,
      ACTIONS_OPTIONS: Object.freeze({
        EDIT: "Modificar",
        DELETE: "Eliminar"
      })
    }),
    DELETE_HOLIDAY_MODAL: Object.freeze({
      TITLE: "Eliminar día inhábil",
      SUBTITLE: "¿Estás seguro que querés eliminar éste día inhábil?",
      TEXT: "Si eliminas éste día inhábil, no será contabilizado en  la sección de caducidad.",
      CONFIRM: "Eliminar",
      DELETING: "Eliminando",
      CANCEL: "Cancelar",
    }),
  }),
  SUBSCRIPTIONS_VIEW: Object.freeze({
    TITLE: "Subscripciones",
    SUBTITLE: "Subscripciones activas",
    TABLE: Object.freeze({
      HEADERS: Object.freeze({
        NAME: "Nombre",
        PRICE: "Precio",
        ACTIONS: OptionsIcon,
      }),
      ACTIONS: Object.freeze({
        EDIT: "Modificar",
        DELETE: "Eliminar"
      }),
      NO_DATA: "No hay subscripciones activas",
    }),
    MODIFY_SUBSCRIPTION: Object.freeze({
      TITLE: "Modificar subscripción",
      FORM: Object.freeze({
        NAME: "Nombre de la suscripción",
        NAME_PLACEHOLDER: "Nombre de la suscripción",
        NEW_PRICE: "Precio Nuevo",
        CURRENT_PRICE: "Precio Actual",
        PRICE_PLACEHOLDER: "Indique un precio",
        SUBMIT: "Guardar",
        REQUIRED: "Campo requerido",
      }),
    }),
  }),
  SERVICES_VIEW: Object.freeze({
    TITLE: "Servicios",
    SUBTITLE: "Servicios desarrollados",
    TABLE: Object.freeze({
      HEADERS: Object.freeze({
        NAME: "Nombre",
        STATUS: "Estado",
        ACTIONS: OptionsIcon,
      }),
      ACTIONS: Object.freeze({
        ACTIVATE: "Activar",
        DEACTIVATE: "Desactivar",
      }),
      NO_DATA: "No hay servicios activos",
    }),
    MODIFY_SERVICE: Object.freeze({
      TITLE: "Modificar servicio",
      FORM: Object.freeze({
        NAME: "Nombre del servicio",
        NAME_PLACEHOLDER: "Nombre del servicio",
        NEW_PRICE: "Precio Nuevo",
        CURRENT_PRICE: "Precio Actual",
        PRICE_PLACEHOLDER: "Indique un precio",
        SUBMIT: "Guardar",
        REQUIRED: "Campo requerido",
      }),
    }),
  }),
  CONFIGURE: "Configurar",
});

export default SETTINGS_VIEW_STRINGS;