import React, {useEffect, useState} from 'react';
import generalService from "../../../services/general.service";
import {Button, Row, Spinner} from "reactstrap";
import {ReactComponent as DisconnectIcon} from "assets/img/icons/notiexpress/disconnect-icon.svg";
import disableServiceModal from "../../Modals/DisableServiceModal";
import enableServiceModal from "../../Modals/EnableServiceModal";

const DisconnectButton = () => {

  const [serviceEnabled, setServiceEnabled] = useState(false);
  const [serviceLoading, setServiceLoading] = useState(false);

  const {
    jurisdictions,
    isError,
    isLoading,
    mutate
  } = generalService.useJurisdictions();

  useEffect(() => {
    if (jurisdictions?.every((jurisdiction: { status: boolean }) => jurisdiction.status)) {
      setServiceEnabled(true);
    } else {
      setServiceEnabled(false)
    }

  }, [jurisdictions]);

  const handleEnableService = async () => {
    setServiceLoading(true);
    try {
      await enableServiceModal().then(async () => {
        await generalService.enableService();
      });
      await mutate();
    } catch (e) {
      //console.log(e);
    } finally {
      setServiceLoading(false);
    }
  }

  const handleDisableService = async () => {
    setServiceLoading(true);
    try {
      await disableServiceModal().then(async () => {
        await generalService.disableService();
      });
      await mutate();
    } catch (e) {
      //console.log(e);
    } finally {
      setServiceLoading(false);
    }
  }

  const handleToggleService = async () => {
    if (serviceEnabled) {
      await handleDisableService();
    } else {
      await handleEnableService();
    }
  }

  return (
    <>
      <Row className="m-0 align-items-center justify-content-center">
        <Button
          className={`btn-noti-icon-primary px-2 ${!serviceEnabled ? 'bg-error' : ''}`}
          onClick={handleToggleService}
        >
          {
            serviceLoading
              ? (
                <Spinner className="p-2" size="sm" color="light"/>
              )
              : (
                serviceEnabled
                  ? <DisconnectIcon className="fill-primary-default" width={30} height={30}/>
                  : <DisconnectIcon className="fill-neutro-1" width={30} height={30}/>
              )
          }
        </Button>
      </Row>
    </>
  );
};

export default DisconnectButton;
