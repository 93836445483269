import React from 'react';
import PropTypes from 'prop-types';
import {Card, CardBody, CardHeader} from "reactstrap";
import UserServiceCard from "../UserServiceCard/UserServiceCard";
import USER_VIEW_STRINGS from "../../assets/strings";

const UserSubscriptions = ({services}) => {


  return (
    <>
      <Card className="shadow-card mt-3">
        <CardHeader>
          <span className="text-heading-2">{USER_VIEW_STRINGS.USER_DETAILS.USER_SUBSCRIPTIONS.TITLE}</span>
        </CardHeader>
        <CardBody>
          {
            services?.map((service, index) => (
              <UserServiceCard key={index} service={service}/>
            ))
          }
        </CardBody>
      </Card>
    </>
  );
};

UserSubscriptions.propTypes = {
  services: PropTypes.array.isRequired,
};

export default UserSubscriptions;
