import useSWR from "swr";
import {API} from "../helpers/fetch";

const usersService= {
  useUsers,
  useCountUsersAndMounts,
  useUser,
  disableUser,
  enableUser,
}
const fetcher = ([url, params]) => {
  return API.get(url, params ? {params} : {})
}

/**
 * Hook for get users
 * @param params
 * @returns {{isLoading: boolean, mutate: (data?: any, shouldRevalidate?: boolean) => Promise<any>, isError: any, data: any}}
 */
function useUsers({params}){
  const  {data, error, mutate} = useSWR(['noticenter/users', params], fetcher);

  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
    mutate
  }
}

/**
 * Hook for get count users and mounts
 * @returns {{isLoading: boolean, mutate: (data?: any, shouldRevalidate?: boolean) => Promise<any>, isError: any, data: any}}
 */
function useCountUsersAndMounts(){
  const  {data, error, mutate} = useSWR(['noticenter/users/services'], fetcher);

  return {
    data,
    isLoading: !error && !data,
    isError: error,
    mutate
  }
}

/**
 * Hook for get user by id
 * @param userId
 * @returns {{isLoading: boolean, mutate: (data?: any, shouldRevalidate?: boolean) => Promise<any>, isError: any, data: any}}
 */
function useUser({userId}) {
  const {data, error, mutate} = useSWR([`noticenter/users/id/${userId}`], fetcher);
  return  {
    data,
    isLoading: !error && !data,
    isError: error,
    mutate
  }
}

function disableUser({userId}) {
  return API.put(`noticenter/users/disable/${userId}`)
}

function enableUser({userId}) {
  return API.put(`noticenter/users/enable/${userId}`)
}
export default usersService