import React from 'react';
import PropTypes from 'prop-types';
import CustomModal from "../../../../components/Modals";
import {Button, Col, ModalBody, Row, Spinner} from "reactstrap";
import SETTINGS_VIEW_STRINGS from "../../assets/strings";
import defaultNonWorkingDaysService from "../../../../services/defaultNonWorkingDays.service";
import { useSWRConfig } from "swr"

const DeleteHolidayModal = ({holidayId, isOpen, toggle}: {holidayId: string, isOpen: boolean, toggle: () => void}) => {
  const { mutate } = useSWRConfig()
  const STRINGS = SETTINGS_VIEW_STRINGS.HOLIDAYS_VIEW.DELETE_HOLIDAY_MODAL;
  const [isLoading, setIsLoading] = React.useState(false);
  const handleDeleteHoliday = async () => {
    setIsLoading(true);
    try {
      await defaultNonWorkingDaysService.deleteDefaultNonWorkingDays({ id: holidayId});
    } catch (error) {
      //console.log(error);
    } finally {
      setIsLoading(false);
      toggle();
      await mutate(["/noticenter/defaultNonWorkingDays"]);
    }
  }

  return (
    <>
      <CustomModal
        isOpen={isOpen}
        toggle={toggle}
        title={STRINGS.TITLE}
        className="w-75"
      >
        <ModalBody>
          <Row>
            <Col>
              <p className="text-heading-2">{STRINGS.SUBTITLE}</p>
              <p className="">{STRINGS.TEXT}</p>
            </Col>
          </Row>
          <Row className="m-0 py-3">
            <Button
              className="btn-noti-primary py-1 px-5"
              onClick={handleDeleteHoliday}
              disabled={isLoading}
            >
              <Row className="m-0 align-items-center">
                {
                  isLoading ? (
                    <Spinner
                      className="mr-2"
                      size="sm"
                      color="light"
                    />
                  ) : null
                }
                <span className="text-button">
                  {
                    isLoading
                      ? STRINGS.DELETING
                      : STRINGS.CONFIRM
                  }
                </span>
              </Row>

            </Button>
          </Row>
        </ModalBody>
      </CustomModal>
    </>
  );
};

DeleteHolidayModal.propTypes = {
  holidayId: PropTypes.string.isRequired,
};

export default DeleteHolidayModal;
