import {ACTIONS} from "./assets/const";

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.USER_LOGGEDIN:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        token: action.payload.token,
      };
    case ACTIONS.USER_LOGGEDOUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        token: null,
      };
    case ACTIONS.USER_SESSION_EXPIRED:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        token: null,
      }
    case ACTIONS.USER_RELOADED:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload,
      }
    default:
      return state;
  }
}
export default reducer;