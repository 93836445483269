import React from 'react'
import HomeHeader from "../../components/Header";
import SETTINGS_VIEW_STRINGS from "./assets/strings";
import {Card, CardBody, CardHeader, Container} from "reactstrap";
import serviceService from "../../services/services.service";
import DynamicTable from "../../components/Tables/DynamicTable";
import serviceColumns from "./assets/servicesColumns";

const ServicesSettingsView = () => {
  const strings = SETTINGS_VIEW_STRINGS.SERVICES_VIEW;
  const {
    jurisdictions,
    isLoading,
    isError,
    mutate
  } = serviceService.useJurisdictions()

  React.useEffect(() => {
    mutate()
  }, [])

  return (
    <>
      <HomeHeader headerText={strings.TITLE} canGoBack/>
      <Container>
        <Card className="shadow-card">
          <CardHeader>
            <span className="text-heading-2">{strings.SUBTITLE}</span>
          </CardHeader>
          <CardBody>
            <DynamicTable
              columns={serviceColumns}
              data={jurisdictions ?? []}
              isLoading={isLoading}
              isError={isError}
              noDataMessage={strings.TABLE.NO_DATA}
            />
          </CardBody>
        </Card>
      </Container>
    </>
  );
}

export default ServicesSettingsView
