const USER_VIEW_STRINGS = Object.freeze({
  TITLE: "Usuarios",
  USERS: "usuarios",
  APROX: "aprox.",
  USERS_TABLE: Object.freeze({
    NO_DATA: "No tenés ningún usuario agregado.",
  }),
  USER_DETAILS: Object.freeze({
    HEADER_TEXT: "Detalle de Usuario",
    USER_DETAILS_HEADER: Object.freeze({
      NAME: "Nombre",
      LAST_NAME: "Apellido",
      EMAIL: "E-mail",
      CREATED_AT: "Alta",
      DELETED_AT: "Baja",
      STATUS: "Estado",
      DISABLE: "Dar de baja",
      ENABLE: "Dar de alta",
    }),
    USER_SUBSCRIPTIONS: Object.freeze({
      TITLE: "Suscripciones del usuario",
    }),
    USER_LAST_LOGIN: Object.freeze({
      TITLE: "Último ingreso a Notiexpress",
      NO_DATA: "El usuario nunca ingresó a Notiexpress.",
    }),
    DELETE_USER: Object.freeze({
      TITLE: "Dar de baja al usuario",
      SUBTITLE: "¿Estás seguro que querés dar de baja al usuario?",
      TEXT: "Si das de baja al usuario, perderá el acceso a su cuenta y sus configuraciones.",
      CONFIRM: "Aceptar",
      CANCEL: "Cancelar",
    }),
  })
});


export default USER_VIEW_STRINGS;