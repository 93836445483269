import React from 'react';
import {Card, CardBody, CardHeader, Row} from "reactstrap";
import DynamicTable from "../../../../components/Tables/DynamicTable";
import COMUNICATIONS_STRINGS from "../../assets/strings";
import newsletterColumns from "../../assets/newsletterColumns";

interface ILastNewslettersProps {
  newsletters: any;
  isError: boolean;
  isLoading: boolean;
}

const STRINGS = COMUNICATIONS_STRINGS.TABLE;
const LastNewsletters = ({ newsletters, isError, isLoading }: ILastNewslettersProps) => {
  return (
    <>
      <Card className="shadow-card my-5">
        <CardHeader >
          <Row className="m-0 align-items-center py-3">
            <span className="text-heading-2">{STRINGS.TITLE}</span>
          </Row>
        </CardHeader>
        <CardBody>
          <DynamicTable
            isLoading={isLoading}
            noDataMessage={STRINGS.NO_DATA}
            isError={isError}
            data={newsletters ?? []}
            columns={newsletterColumns}
          />
        </CardBody>
      </Card>
    </>
  );
};


export default LastNewsletters;
