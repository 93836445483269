import React from "react";
import SETTINGS_VIEW_STRINGS from "./strings";
import { ReactComponent as EllipsisVerticalIcon } from "assets/img/icons/notiexpress/ellipsis-vertical-icon.svg";
import ServiceOptionDropdown from "../components/ServiceOptionDropdown";

const strings = SETTINGS_VIEW_STRINGS.SERVICES_VIEW.TABLE.HEADERS;
const OptionsIcon = strings.ACTIONS;
const subscriptionsColumns = [
  {
    Header: strings.NAME,
    accessor: (row) => (
      <span className="text-body-regular">{`${row?.name}`}</span>
    ),
  },
  {
    Header: strings.STATUS,
    accessor: (row) => (
      <span className="text-body-regular">
        {row.status ? "Activo" : "Inactivo"}
      </span>
    ),
  },
  {
    Header: () => (
      <p className="text-center m-0" style={{ width: "30px" }}>
        <OptionsIcon
          width={20}
          height={20}
          className="stroke-secondary-default"
        />
      </p>
    ),
    accessor: "_id",
    Cell: ({row}) => <ServiceOptionDropdown service={row.original} />,
  },
];

export default subscriptionsColumns;
