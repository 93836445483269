import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { ReactComponent as TagIcon } from "assets/img/icons/notiexpress/tag-icon.svg";
import { ReactComponent as DownLoadIcon } from "assets/img/icons/notiexpress/download-icon.svg";
import VOUCHERS_VIEW_STRINGS from "../../assets/strings";
import moment from "moment";
import print from "context/print";

const VoucherListItem = ({ voucher }) => {
  const strings = VOUCHERS_VIEW_STRINGS.VOUCHERS_LIST.VOUCHER_LIST_ITEM;

  const {
    actions: { setPrintData },
  } = useContext(print);

  const handleDownloadVoucher = () => {
        setPrintData({
      type: "voucher",
      printData: voucher,
      printTitle: "Voucher",
      printSubtitle: "Voucher",
    });
  };

  return (
    <>
      <Card className="shadow-item my-3">
        <CardBody>
          <Row>
            <Col xs={12} md={2}>
              <Row className="m-0 align-items-center justify-content-center h-100">
                <TagIcon
                  width={20}
                  height={20}
                  className="stroke-primary-default mr-2"
                />
                <span className="text-heading-2">
                  {voucher?.countAvailable ?? "-"}
                </span>
              </Row>
            </Col>
            <Col xs={12} md={2}>
              <Row className="m-0 align-items-center justify-content-center h-100">
                <span className="text-heading-2">
                  {`-${voucher?.amount ?? "-"} ${strings.SYMBOL}`}
                </span>
              </Row>
            </Col>
            <Col xs={12} md={6}>
              <Row className="m-0 align-items-center justify-content-center h-100">
                <span className="text-body-large-bold text-secondary-default">
                  {strings.VALID}
                </span>
                <span className="text-body-large ml-2">
                  {`${strings.SINCE}: ${
                    moment(voucher?.startDate).format("DD/MM/YYYY") ?? "-"
                  }`}
                </span>
                <span className="text-body-large ml-2">
                  {`${strings.UNTIL}: ${
                    moment(voucher?.endDate).format("DD/MM/YYYY") ?? "-"
                  }`}
                </span>
              </Row>
            </Col>
            <Col xs={12} md={2}>
              <Row className="m-0 align-items-center justify-content-center h-100">
                <Button
                  className="btn-noti-icon-primary px-2"
                  onClick={handleDownloadVoucher}
                >
                  <DownLoadIcon
                    width={24}
                    height={24}
                    className="stroke-primary-default"
                  />
                </Button>
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

VoucherListItem.propTypes = {
  voucher: PropTypes.object.isRequired,
};

export default VoucherListItem;
