import React from 'react';
import {Button, Col, Form, FormGroup, Input, InputGroup, Label, Row} from "reactstrap";
import "./UsersSearch.css"
import TABLE_OPTIONS_STRINGS from "../../../assets/strings";
import {useFormik} from "formik";
import * as Yup from "yup";
import {ReactComponent as SearchIcon} from "assets/img/icons/notiexpress/search-icon.svg";
import {ReactComponent as CloseIcon} from "assets/img/icons/notiexpress/close-icon.svg";
import {parseObjectToQS} from "../../../../../helpers/utils";
import {USER_STATUS} from "../../../../../helpers/consts";
import {useHistory} from "react-router-dom";

const usersSearchSchema = Yup.object().shape({
  userOrEmail: Yup.string(),
  status: Yup.string(),
})

const UsersSearch = ({resetSearch}) => {
  const history = useHistory();

  const strings = TABLE_OPTIONS_STRINGS.USERS_SEARCH;
  const currentParams = history.location.search ? history.location.search : {};

  const statusOptions = [
    {value: '', label: strings.USER_STATUS_OPTIONS.ALL},
    {
      value: USER_STATUS.ACTIVE,
      label: strings.USER_STATUS_OPTIONS.ACTIVE
    },
    {
      value: USER_STATUS.INACTIVE,
      label: strings.USER_STATUS_OPTIONS.INACTIVE
    },
    {
      value: USER_STATUS.PENDING,
      label: strings.USER_STATUS_OPTIONS.PENDING
    },
  ];

  const initialValues = {
    userOrEmail: currentParams?.userOrEmail ?? '',
    status: currentParams?.status ?? ''
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: usersSearchSchema,
    onSubmit: values => {
      const newParams = {
        ...(values.userOrEmail && { userOrEmail: values.userOrEmail }),
        ...(values.status && { status: values.status }),
      };
      history.push({
        pathname: history.location.pathname,
        search: parseObjectToQS(newParams)
      });
    },
    onReset: () => {
      history.push({
        pathname: history.location.pathname,
        search: ''
      });
      resetSearch();
    }
  })

  return (
    <>
      <Form onSubmit={formik.handleSubmit} className="py-3">
        <Row >
          <Col className="filter-box">
            <FormGroup>
              <Label
                for="emailOrName"
                className="text-body-large-bold text-secondary-default"
              >
                {TABLE_OPTIONS_STRINGS.USERS_SEARCH.USER_OR_EMAIL}
              </Label>
              <InputGroup className="input-group-alternative ">
                <Input
                  id="emailOrName"
                  name="userOrEmail"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.userOrEmail}
                  className="form-control"
                  placeholder={TABLE_OPTIONS_STRINGS.USERS_SEARCH.USER_OR_EMAIL_PLACEHOLDER}
                  type="text"
                  bsSize="sm"
                />
              </InputGroup>
            </FormGroup>
          </Col>
          <Col className="filter-box">
            <FormGroup>
              <Label
                for="status"
                className="text-body-large-bold text-secondary-default"
              >
                {TABLE_OPTIONS_STRINGS.USERS_SEARCH.USER_STATUS}
              </Label>
              <InputGroup className="input-group-alternative ">
                <Input
                  id="status"
                  type="select"
                  name="status"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.status}
                  className="form-control"
                  placeholder={TABLE_OPTIONS_STRINGS.USERS_SEARCH.USER_STATUS_PLACEHOLDER}
                  bsSize={"sm"}
                >
                  {
                    statusOptions.map((option, index) => (
                      <option
                        key={index}
                        value={option.value}
                      >
                        {option.label}
                      </option>
                    ))
                  }
                </Input>
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>
        <Row className="m-0">
          <Button
            className="btn-noti-primary py-1 px-5"
            type="submit"
          >
            <Row
              className="m-0 align-items-center"
            >
              <SearchIcon width={20} height={20} className="stroke-neutro-1"/>
              <span className="text-button">{strings.SUBMIT}</span>
            </Row>
          </Button>
          <Button
            className=""
            type="button"
            onClick={() => {
              formik.resetForm();
            }}
          >
            <Row
              className="m-0 align-items-center"
            >
              <CloseIcon width={20} height={20} className="stroke-primary-default"/>
              <span className="">{strings.CLEAR}</span>
            </Row>
          </Button>
          <Col>
          </Col>
        </Row>
      </Form>
    </>
  )
    ;
};

export default UsersSearch;
