import React, {useState} from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Row,
  Spinner
} from "reactstrap";
import {ReactComponent as PlusIcon} from "assets/img/icons/notiexpress/plus-icon.svg";
import {ReactComponent as CloseIcon} from "assets/img/icons/notiexpress/close-icon.svg";
import {ReactComponent as CheckIcon} from "assets/img/icons/notiexpress/check-icon.svg";
import COMUNICATIONS_STRINGS from "../../assets/strings";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '@ckeditor/ckeditor5-build-classic/build/translations/es';
import {useFormik} from "formik";
import * as Yup from "yup";
import newsletterService from "../../../../services/newsletter.service";
import PreviewNewsletterModal from "../PreviewNewsletterModal";
import config from "../../assets/ckEditorConfig";
import CustomMessage from "../../../../components/CustomMessage";
import {useSWRConfig} from "swr";

const addNewsletterSchema = Yup.object().shape({
  to: Yup.string().required(),
  toInput: Yup.string(),
  title: Yup.string().required(),
  body: Yup.string().required()
});

const STRINGS = COMUNICATIONS_STRINGS.ADD_NEWSLETTER;
const AddNewsletter = () => {
  const {mutate} = useSWRConfig();
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);

  const initialValues = {
    to: "all",
    toInput: "",
    title: "",
    body: ""
  }

  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const togglePreviewModal = () => setOpenPreviewModal(!openPreviewModal);

  const handleSubmit = async (values: any) => {
    formik.setSubmitting(true);
    try {
      await newsletterService.sendAndSaveNewsletter({
        title: values.title,
        subject: values.title,
        subtitle: values.title,
        description: values.title,
        body: values.body,
        receivers: values.to,
        to: [values.toInput]
      });
      setShowSuccess(true);
    } catch (error) {
      // console.log(error)
      setShowError(true);
    } finally {
      formik.setSubmitting(false);
      await mutate(['/noticenter/newsletter']);
    }
  }
  const formik = useFormik({
    initialValues,
    validationSchema: addNewsletterSchema,
    onSubmit: handleSubmit
  });

  const handleChangeEditor = (event: any, editor: any) => {
    const data = editor.getData();
    formik.setFieldValue("body", data);
  }


  return (
    <>
      <Card className="shadow-card">
        <CardHeader>
          <Row className="m-0 align-items-center py-3">
            <PlusIcon width={24} height={24} className="stroke-primary-default mr-2"/>
            <span className="text-heading-2">{STRINGS.TITLE}</span>
          </Row>
        </CardHeader>
        <CardBody>
          <Form onSubmit={formik.handleSubmit}>
            <Col>
              <Row>
                <Col>
                  <span className="text-body-large-bold text-secondary-default">
                    {STRINGS.FORM.TO.LABEL}
                  </span>
                  <FormGroup
                    className="m-0 p-3"
                    row
                    tag="fieldset"
                    onChange={formik.handleChange}
                  >
                    <FormGroup className="custom-radio" tag={Col} check>
                      <Input
                        name="to"
                        className="custom-control-input"
                        checked={formik.values.to === STRINGS.FORM.TO.OPTIONS.ALL}
                        value={STRINGS.FORM.TO.OPTIONS.ALL}
                        id={STRINGS.FORM.TO.OPTIONS.ALL}
                        type="radio"
                        onChange={formik.handleChange}
                      />
                      <Label check for={STRINGS.FORM.TO.OPTIONS.ALL} className="custom-control-label">
                        <span className="text-body-regular">{STRINGS.FORM.TO.OPTIONS_LABELS.ALL}</span>
                      </Label>
                    </FormGroup>
                    <FormGroup className="custom-radio" tag={Col} check>
                      <Input
                        name="to"
                        className="custom-control-input"
                        checked={formik.values.to === STRINGS.FORM.TO.OPTIONS.PENDING}
                        value={STRINGS.FORM.TO.OPTIONS.PENDING}
                        id={STRINGS.FORM.TO.OPTIONS.PENDING}
                        type="radio"
                        onChange={formik.handleChange}
                      />
                      <Label check for={STRINGS.FORM.TO.OPTIONS.PENDING} className="custom-control-label">
                        <span className="text-body-regular">{STRINGS.FORM.TO.OPTIONS_LABELS.PENDING}</span>
                      </Label>
                    </FormGroup>
                    <FormGroup className="custom-radio" tag={Col} check>
                      <Input
                        name="to"
                        className="custom-control-input"
                        checked={formik.values.to === STRINGS.FORM.TO.OPTIONS.UP_TO_DATE}
                        value={STRINGS.FORM.TO.OPTIONS.UP_TO_DATE}
                        id={STRINGS.FORM.TO.OPTIONS.UP_TO_DATE}
                        type="radio"
                        onChange={formik.handleChange}
                      />
                      <Label check for={STRINGS.FORM.TO.OPTIONS.UP_TO_DATE} className="custom-control-label">
                        <span className="text-body-regular">{STRINGS.FORM.TO.OPTIONS_LABELS.UP_TO_DATE}</span>
                      </Label>
                    </FormGroup>
                    <FormGroup className="custom-radio" tag={Col} check>
                      <Input
                        name="to"
                        className="custom-control-input"
                        checked={formik.values.to === STRINGS.FORM.TO.OPTIONS.CUSTOM}
                        value={STRINGS.FORM.TO.OPTIONS.CUSTOM}
                        id={STRINGS.FORM.TO.OPTIONS.CUSTOM}
                        type="radio"
                        onChange={formik.handleChange}
                      />
                      <Label check for={STRINGS.FORM.TO.OPTIONS.CUSTOM} className="custom-control-label">
                        <span className="text-body-regular">{STRINGS.FORM.TO.OPTIONS_LABELS.CUSTOM}</span>
                      </Label>
                    </FormGroup>
                    <FormGroup>
                      <InputGroup className="input-group-alternative">
                        <Input
                          name="toInput"
                          value={formik.values.toInput}
                          disabled={formik.values.to !== STRINGS.FORM.TO.OPTIONS.CUSTOM}
                          placeholder={STRINGS.FORM.TO.PLACEHOLDER}
                          id="toInput"
                          type="text"
                          onChange={formik.handleChange}
                        />
                      </InputGroup>
                    </FormGroup>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label for="title" className="text-body-large-bold text-secondary-default">
                      {STRINGS.FORM.TITLE}
                    </Label>
                    <InputGroup className="input-group-alternative">
                      <Input
                        type="text"
                        value={formik.values.title}
                        onChange={formik.handleChange}
                        name="title"
                        id="title"
                        placeholder={STRINGS.FORM.TITLE_PLACEHOLDER}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <CKEditor
                    editor={ClassicEditor}
                    data={formik.values.body}
                    onChange={handleChangeEditor}
                    config={config}
                  />
                </Col>
              </Row>
              <Row className="m-0 py-4 align-items-center">
                <Button
                  className="btn-outline-noti-secondary py-1 px-5 mr-3"
                  onClick={togglePreviewModal}
                >
                  <span className="">
                    {STRINGS.FORM.PREVIEW}
                  </span>
                </Button>
                <Button
                  className="btn-noti-primary py-1 px-5"
                  type="submit"
                  disabled={formik.isSubmitting}
                >
                  <Row className="m-0 align-items-center">
                    {
                      formik.isSubmitting ?
                        <Spinner size="sm" color="white" className="mr-2"/> : null
                    }
                    <span className="text-button">{STRINGS.FORM.SUBMIT}</span>
                  </Row>
                </Button>
                <Col>
                  {
                    showError && (
                      <CustomMessage
                        icon={<CloseIcon width={24} height={24} className="stroke-error"/>}
                        message={STRINGS.FORM.ERROR}
                        className="m-0"
                      />
                    )
                  }
                  {
                    showSuccess && (
                      <CustomMessage
                        icon={<CheckIcon width={24} height={24} className="stroke-success"/>}
                        message={STRINGS.FORM.SUCCESS}
                        className="m-0"
                      />
                    )
                  }
                </Col>
              </Row>
            </Col>
          </Form>
        </CardBody>
      </Card>
      <PreviewNewsletterModal
        isOpen={openPreviewModal}
        toggle={togglePreviewModal}
        newsletter={formik.values}
      />
    </>
  );
};

AddNewsletter.propTypes = {};

export default AddNewsletter;
